import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'

import { history } from './configureStore';

import HomeRoute from './routes/routes.home'
import ErrorRoute from './routes/routes.error'

import Tooltip from './kit/components/Tooltip/Tooltip.js'
import Toast from './kit/components/Toast/Toast.js'


import {
  tryToCreateDatabase
} from './actions/actions.export'

class App extends Component {
  

  componentWillMount(){
    const { dispatch } = this.props;

    dispatch(tryToCreateDatabase());
  }

  render(){

    const { guiReducer } = this.props;

    return (
      <div className="app">
        <Tooltip/>
        <ConnectedRouter history={history}>

          <Toast/>
          <Switch>
            <Route exact path="/" render={(props) => {
              return <HomeRoute/>
            }}/>

            <Route exact path="/oops" component={ErrorRoute}/>
            <Redirect to="/oops"/>
          </Switch>
        </ConnectedRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { 
    guiReducer
  } = state;

  return {
    guiReducer
  }
}

export default connect(mapStateToProps)(App);