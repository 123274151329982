import React, { Component } from 'react'
import shortid from 'shortid'
import { Link } from 'react-router-dom'

import './Modal.scss';

import CustomButton from '../CustomButton/CustomButton'

class Modal extends Component {

  constructor(props){
    super(props);

    this.state = {
      id: shortid.generate(),
    }

    this.wrapperRef = React.createRef();
    this.selectOverflowGrabberRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.shiftSelectGrabber = this.shiftSelectGrabber.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside);
    document.addEventListener("scroll", this.shiftSelectGrabber);
    
    this.shiftSelectGrabber();
    
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside);
    document.removeEventListener("scroll", this.shiftSelectGrabber);
  }

  handleClickOutside(event) {
    if(this.wrapperRef && this.selectOverflowGrabberRef) {
      if(this.wrapperRef.current && this.selectOverflowGrabberRef.current){
        if(!this.wrapperRef.current.contains(event.target) && !this.selectOverflowGrabberRef.current.contains(event.target)){
          if(this.props.exitable && this.props.onExit){
            this.props.onExit(event);
          }
        }
      }
    }
  }

  componentWillReceiveProps(newprops){
    if(newprops.selectOpen !== this.state.selectOpen){
      this.shiftSelectGrabber();
      this.setState({
        selectOpen: newprops.selectOpen
      })
    }
  }

  shiftSelectGrabber(){
    if(!this.state.selectOpen && this.props.show){
      document.getElementById('modal-select-grabber').style.top = document.body.getBoundingClientRect().top + 'px';
    }
  }

  render(){

    if(this.props.show){
      return <div className="modal" id={"modal-wrapper"}>
        <div id="modal-select-grabber" ref={this.selectOverflowGrabberRef}></div>
        <div 
          className={"modal-inner" + (this.props.exitable ? " modal-inner-exitable" : "")} 
          style={{maxWidth: this.props.maxWidth, minWidth: this.props.minWidth}}
          ref={this.wrapperRef}
          >
          {
            this.props.exitable && <div className="modal-inner-exit-button" onClick={this.props.onExit}>
              <i className="far fa-times"/>
            </div>
          }

          {this.props.content}

          {
            this.props.acceptable && <div className="modal-inner-button-row">
              {
                this.props.cancelable && 
                <CustomButton
                  display={this.props.cancelButtonLabel || "Cancel"}
                  onClick={this.props.onCancel}
                  thinking={this.props.cancelButtonThinking}
                  disabled={this.props.cancelButtonDisabled}
                  color={this.props.cancelButtonColor || 'transparent'}
                  />
              }
              {
                this.props.acceptable && 
                <CustomButton
                  display={this.props.acceptButtonLabel || "Ok"}
                  thinking={this.props.acceptButtonThinking}
                  disabled={this.props.acceptButtonDisabled}
                  success={this.props.acceptButtonSuccess}
                  fail={this.props.acceptButtonFail}
                  color={this.props.acceptButtonColor || "black"}
                  onClick={this.props.onAccept}
                  />
              }
            </div>
          }
        </div>
      </div>
    } else {
      return <span/>
    } 
  }
}

export default Modal
  