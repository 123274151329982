import React, { Component } from 'react'
import { push, goBack } from 'react-router-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import shortid from 'shortid'

import './Toast.css';

import { dismissToast } from '../../../actions/actions.export.js';

class Toast extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
  }

  handleDismiss(toast, index){
    const { dispatch } = this.props;

    var el = document.getElementById('toast-' + toast.id);
    el.classList.add('toast-dismissed')
    
    setTimeout((e) => {
      dispatch(dismissToast(toast))
    }, 450)
  }

  render(){
    const { dispatch, toast } = this.props; 
    var that = this;

    return <div className="toast-wrapper">
      {
        toast.toast_list.map((t, i) => {
          return <div 
            className="toast"
            key={i}
            id={"toast-" + t.id}
            >
            <div className="toast-text-wrapper">
              <div className="toast-title">{ t.title }</div>
              <div className="toast-detail">{ t.detail }</div>
            </div>
            <div className="toast-button-wrapper">
              {
                t.buttons.map((b, j) => {
                  return <div className="toast-button" onClick={(e) => {
                    if(b.onClick) b.onClick(e);

                    if(b.dismissOnClick){
                      that.handleDismiss(t, i);
                      if(window.location.pathname === b.destination){
                        dispatch(goBack());
                      }
                    } 
                    if(b.destination){
                      if(window.location.pathname != b.destination){
                        this.props.history.push(b.destination);
                      }
                    } 
                    }} key={j}>
                    { b.display }
                  </div>
                })
              }
            </div>
          </div>
        })
      }
    </div>
    
  }
}
const mapStateToProps = (state) => {
  const { toast } = state;

  return {
    toast
  }
}

export default connect(mapStateToProps)(withRouter(Toast));
