import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import CustomField from '../../kit/components/CustomField/CustomField'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect'

import {
  setEditingChart,
  updateChart
} from '../../actions/actions.export'

import './ChartEditor.scss';

import version from '../../version';

import categorizeValues from '../../utilities/categorizeValues'
import rebuildData from '../../utilities/rebuildData'

class ChartEditor extends Component {

  constructor(props){
    super(props)

    this.state = {
      fields: [],
      x_axis_details: false
    }

  }


  componentWillMount(){
    const { dispatch, dashReducer } = this.props;

    const view = dashReducer.views.find(v => v.id === dashReducer.editingChart.view_id);
    const chart = view.layout.find(c => c.i === dashReducer.editingChart.chart_id);

    let data = rebuildData(chart.result);

    let fields = chart.result.columns.map(c => {

      let type = categorizeValues(data.map(d => d[c]));

      return {
        name: c,
        type: type,
        value: c,
        label: <span>
          {type === 'number' && <i className="fal fa-tally fa-fw icon-before-text"/>}
          {type === 'string' && <i className="fal fa-text fa-fw icon-before-text"/>}
          {type === 'date' && <i className="fal fa-calendar fa-fw icon-before-text"/>}
          {c}
        </span>
      }
    });

    this.setState({
      fields: fields
    })
  }


  render(){
    const { dispatch, dashReducer } = this.props;

    const view = dashReducer.views.find(v => v.id === dashReducer.editingChart.view_id);
    let chart = view.layout.find(c => c.i === dashReducer.editingChart.chart_id);

    if(!chart) return <div className={"chart-editor"}>
      <div className="flex-split">
        <h4 className="no-margin">Chart Editor</h4>
        <CustomButton
          display="Close Editor"
          size="small"
          color="success"
          onClick={e => dispatch(setEditingChart())}
          />
      </div>
    </div>

    if(!chart.x_axis_type) chart.x_axis_type = (this.state.fields.find(d => d.value === chart.x_axis) || {}).type;
    if(!chart.y_axis_type) chart.y_axis_type = (this.state.fields.find(d => d.value === chart.y_axis) || {}).type;


    let chart_config = {
      'vertical-bar': {
        x_axis: true,
        x_axis_details: true,
        x_axis_type: true,
        x_axis_sort: true,
        x_axis_sort_direction: true,
        x_axis_range: true,
        y_axis: true,
        y_axis_details: true,
        y_axis_range: true
      },
      'stacked-bar': {
        x_axis: true,
        x_axis_details: true,
        x_axis_type: true,
        x_axis_sort: true,
        x_axis_sort_direction: true,
        x_axis_range: true,
        y_axis: true,
        y_axis_details: true,
        y_axis_range: true,
        split_data_by: true,
      },
      'horizontal-bar': {
        x_axis: true,
        y_axis: true,
      },
      'line-chart': {
        x_axis: true,
        x_axis_details: true,
        x_axis_type: true,
        x_axis_sort: true,
        x_axis_sort_direction: true,
        x_axis_range: true,
        y_axis: true,
        y_axis_details: true,
        y_axis_range: true
      },
      'multi-line-chart': {
        split_data_by: true,
        x_axis: true,
        x_axis_details: true,
        x_axis_type: true,
        x_axis_sort: true,
        x_axis_sort_direction: true,
        x_axis_range: true,
        y_axis: true,
        y_axis_details: true,
        y_axis_range: true
      }
    }


    return <div className={"chart-editor"}>
      <div className="flex-split">
        <h4 className="no-margin">Chart Editor</h4>
        <CustomButton
          display="Close Editor"
          size="small"
          color="success"
          onClick={e => dispatch(setEditingChart())}
          />
      </div>

      <div className="">
        <CustomField
          value={chart.title}
          label="Chart Title"
          onChange={e => dispatch(updateChart({
            view_id: view.id, 
            chart_id: chart.i,
            data: {
              title: e.value
            }
          }))}
          />

        <CustomField
          value={chart.description}
          label="Description"
          rows={4}
          onChange={e => dispatch(updateChart({
            view_id: view.id, 
            chart_id: chart.i,
            data: {
              description: e.value
            }
          }))}
          />

        <CustomSelect
          value={chart.chart_type}
          label="Chart Type"
          isClearable={true}
          options={[
            {
              label: "No Chart",
              value: "no-chart"
            },
            {
              label: "Bar Chart",
              value: "vertical-bar"
            },
            {
              label: "Stacked Bar Chart",
              value: "stacked-bar"
            },
            {
              label: "Horizontal Bar Chart",
              value: "horizontal-bar"
            },
            {
              label: "Line Chart",
              value: "line-chart"
            },
            {
              label: "Multi-Line Chart",
              value: "multi-line-chart"
            }
          ]}
          onChange={e => dispatch(updateChart({
            view_id: view.id, 
            chart_id: chart.i,
            data: {
              chart_type: e
            }
          }))}
          />
      </div>
      <hr/>

      {
        chart_config[chart.chart_type] &&

        <div>


        {
          chart_config[chart.chart_type].x_axis && 
          <CustomSelect
            value={chart.x_axis}
            label="X Axis"
            options={this.state.fields}
            isClearable={true}
            onChange={e => {
              let f = this.state.fields.find(d => d.value === e);
              dispatch(updateChart({
                view_id: view.id, 
                chart_id: chart.i,
                data: {
                  x_axis: e,
                  x_axis_type: undefined
                }
              }))
            }}
            />
        }

        {
          chart_config[chart.chart_type].x_axis_details && 
          <div>
            <div className="text-hover-primary clickable" onClick={e => this.setState({x_axis_details: !this.state.x_axis_details})}><i className={"fas fa-fw " + (this.state.x_axis_details ? "fa-caret-down" : "fa-caret-right")}/> X Axis Details</div>
            {
              this.state.x_axis_details &&
              <div className="margin-left-2rem">

              {
                chart_config[chart.chart_type].x_axis_type && 
                <CustomSelect
                  value={chart.x_axis_type}
                  label="X Axis Type"
                  isClearable={true}
                  options={[
                    {
                      label: "Default", 
                      value: undefined
                    },
                    {
                      label: "Numeric", 
                      value: "number"
                    },
                    {
                      label: "String / Text", 
                      value: "string"
                    },
                    {
                      label: "Date / Time", 
                      value: "date"
                    },
                  ]}
                  onChange={e => dispatch(updateChart({
                    view_id: view.id, 
                    chart_id: chart.i,
                    data: {
                      x_axis_type: e
                    }
                  }))}
                  />
              }


              {
                chart_config[chart.chart_type].x_axis_sort && 
                <CustomSelect
                  value={chart.x_axis_sort}
                  label="X Axis Sort"
                  isClearable={true}
                  disabled={chart.x_axis_type === 'number'}
                  options={[
                    {
                      label: "By Measure", 
                      value: "measure"
                    },
                    {
                      label: "Automatic", 
                      value: "automatic"
                    },
                    {
                      label: "Default",
                      value: undefined
                    },
                  ]}
                  onChange={e => dispatch(updateChart({
                    view_id: view.id, 
                    chart_id: chart.i,
                    data: {
                      x_axis_sort: e
                    }
                  }))}
                  />
              }

              {
                chart_config[chart.chart_type].x_axis_sort_direction && 
                <CustomSelect
                  value={chart.x_axis_sort_direction}
                  label="X Axis Direction"
                  isClearable={true}
                  options={[
                    {
                      label: "Ascending", 
                      value: undefined
                    },
                    {
                      label: "Descending", 
                      value: "desc"
                    }
                  ]}
                  onChange={e => dispatch(updateChart({
                    view_id: view.id, 
                    chart_id: chart.i,
                    data: {
                      x_axis_sort_direction: e
                    }
                  }))}
                  />
                }



                {
                  chart_config[chart.chart_type].x_axis_range && 
                  <div className="flex-split">
                    <CustomField
                      value={chart.x_axis_min}
                      disabled={chart.x_axis_type === 'string'}
                      placeholder="Auto"
                      label="X Axis Min"
                      type={chart.x_axis_type}
                      onChange={e => dispatch(updateChart({
                        view_id: view.id, 
                        chart_id: chart.i,
                        data: {
                          x_axis_min: e.value || null
                        }
                      }))}
                      />
                    <CustomField
                      value={chart.x_axis_max}
                      disabled={chart.x_axis_type === 'string'}
                      placeholder="Auto"
                      label="X Axis Max"
                      type={chart.x_axis_type}
                      onChange={e => dispatch(updateChart({
                        view_id: view.id, 
                        chart_id: chart.i,
                        data: {
                          x_axis_max: e.value || null
                        }
                      }))}
                      />
                  </div>
                }
              </div>
            }

          </div>
        }


        {
          chart_config[chart.chart_type].y_axis && 
          <CustomSelect
            value={chart.y_axis}
            label="Y Axis"
            options={this.state.fields.filter(f => f.type === 'number')}
            isClearable={true}
            multiSelect={(chart.chart_type === 'multi-line-chart' || chart.chart_type === 'stacked-bar') && !chart.split_data_by}
            onChange={e => dispatch(updateChart({
              view_id: view.id, 
              chart_id: chart.i,
              data: {
                y_axis: e
              }
            }))}
            />
        }

        {
          chart_config[chart.chart_type].y_axis_details && 
          <div>
            <div className="text-hover-primary clickable" onClick={e => this.setState({y_axis_details: !this.state.y_axis_details})}><i className={"fas fa-fw " + (this.state.y_axis_details ? "fa-caret-down" : "fa-caret-right")}/> Y Axis Details</div>
            {
              this.state.y_axis_details &&
              <div className="margin-left-2rem relative">

              {
                chart_config[chart.chart_type].y_axis_range && 
                <div className="flex-split">
                  <CustomField
                    value={chart.y_axis_min}
                    placeholder="Auto"
                    label="Y Axis Min"
                    type="number"
                    onChange={e => dispatch(updateChart({
                      view_id: view.id, 
                      chart_id: chart.i,
                      data: {
                        y_axis_min: e.value || null
                      }
                    }))}
                    />
                  <CustomField
                    value={chart.y_axis_max}
                    placeholder="Auto"
                    label="Y Axis Max"
                    type="number"
                    onChange={e => dispatch(updateChart({
                      view_id: view.id, 
                      chart_id: chart.i,
                      data: {
                        y_axis_max: e.value || null
                      }
                    }))}
                    />
                </div>
              }
              </div>
            }
          </div>
        }



        {
          chart_config[chart.chart_type].split_data_by && 
          <CustomSelect
            value={chart.split_data_by}
            label="Series Field"
            options={this.state.fields}
            isClearable={true}
            disabled={Array.isArray(chart.y_axis) && chart.y_axis.length > 1}
            description={"If a column in your data should be used to isolate sections of the table into separate sets, select it here."}
            onChange={e => {
              let f = this.state.fields.find(d => d.value === e);
              dispatch(updateChart({
                view_id: view.id, 
                chart_id: chart.i,
                data: {
                  split_data_by: e
                }
              }))
            }}
            />
        }
        
      </div>
    }
{/*
      {
        chart.chart_type === 'horizontal-bar' && 
        <div>
          <div className="flex-split">
            <div className="flex-50 margin-right-1rem">
              <CustomSelect
                value={chart.y_axis}
                label="Y Axis"
                options={this.state.fields}
                onChange={e => dispatch(updateChart({
                  view_id: view.id, 
                  chart_id: chart.i,
                  data: {
                    x_axis: e
                  }
                }))}
                />
            </div>
            <div className="flex-50 margin-left-1rem">
              <CustomSelect
                value={chart.y_axis}
                label="Measure"
                options={this.state.fields.filter(f => f.type === 'number')}
                onChange={e => dispatch(updateChart({
                  view_id: view.id, 
                  chart_id: chart.i,
                  data: {
                    y_axis: e
                  }
                }))}
                />
            </div>
          </div>
        </div>
      }



      {
        chart.chart_type === 'line-chart' && 
        <div className="">
          <div className="flex-split">
            <div className="flex-50 margin-right-1rem">
              <CustomSelect
                value={chart.x_axis}
                label="X Axis"
                options={this.state.fields}
                onChange={e => dispatch(updateChart({
                  view_id: view.id, 
                  chart_id: chart.i,
                  data: {
                    x_axis: e
                  }
                }))}
                />
            </div>
            <div className="flex-50 margin-left-1rem">
              <CustomSelect
                value={chart.y_axis}
                label="Measure"
                options={this.state.fields.filter(f => f.type === 'number')}
                onChange={e => dispatch(updateChart({
                  view_id: view.id, 
                  chart_id: chart.i,
                  data: {
                    y_axis: e
                  }
                }))}
                />
            </div>
          </div>
        </div>
      }*/}

    </div>
  }
}


const mapStateToProps = (state) => {
  const { dashReducer, chatReducer } = state;

  return {
    dashReducer, chatReducer
  }
}

export default connect(mapStateToProps)(ChartEditor);

  