import {
  setHistory,
  addToast
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Generate a text description of a table
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestDescribeTable = packet => {
  return {
    type: 'REQUEST_DESCRIBE_TABLE',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveDescribeTableSuccess = data => ({
  type: 'RECEIVE_DESCRIBE_TABLE_SUCCESS',
  data
})

export const receiveDescribeTableFail = data => ({
  type: 'RECEIVE_DESCRIBE_TABLE_FAIL',
  data
})

export const tryToDescribeTable = packet => (dispatch, getState) => {

  dispatch(requestDescribeTable(packet));


  let headers = {
    "Content-Type": "application/json"
  }

  return fetch('/api/dash/describe-table', {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveDescribeTableSuccess({
        data_id: packet.data_id,
        response: json
      }));
      dispatch(setHistory());
    })
    .catch(e => {
      
      dispatch(receiveDescribeTableFail({errors:e, lastSubmit: packet}));
      dispatch(addToast({
        title: "Failed to Respond",
        // detail: e.error || e,
        autoDismiss: 5000
      }))
    })
}
