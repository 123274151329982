import shortid from 'shortid';

function toSqlSafeFieldName(str) {
  let safeStr = str.replace(/[^a-zA-Z0-9]/g, '_');

  if (/^[0-9]/.test(safeStr)) {
    safeStr = '_' + safeStr;
  }

  const MAX_LENGTH = 128;  // MySQL's maximum identifier length
  if (safeStr.length > MAX_LENGTH) {
    safeStr = safeStr.substring(0, MAX_LENGTH - 1);
  }

  const RESERVED_WORDS = [
    'add', 'all', 'alter', 'analyze', 'and', 'as', 'asc', 'asensitive', 'before',
    'between', 'bigint', 'binary', 'blob', 'both', 'by', 'call', 'cascade',
    'case', 'change', 'char', 'character', 'check', 'collate', 'column', 'condition',
    'constraint', 'continue', 'convert', 'create', 'cross', 'current_date', 'current_time',
    'current_timestamp', 'current_user', 'cursor', 'database', 'databases',
    'day_hour', 'day_microsecond', 'day_minute', 'day_second', 'dec', 'decimal',
    'declare', 'default', 'delayed', 'delete', 'desc', 'describe', 'deterministic',
    'distinct', 'distinctrow', 'div', 'double', 'drop', 'dual', 'each', 'else',
    'elseif', 'enclosed', 'escaped', 'exists', 'exit', 'explain', 'false', 'fetch',
    'float', 'float4', 'float8', 'for', 'force', 'foreign', 'from', 'fulltext',
    'grant', 'group', 'having', 'high_priority', 'hour_microsecond', 'hour_minute',
    'hour_second', 'if', 'ignore', 'in', 'index', 'infile', 'inner', 'inout', 'insensitive',
    'insert', 'int', 'int1', 'int2', 'int3', 'int4', 'int8', 'integer', 'interval',
    'into', 'is', 'iterate', 'join', 'key', 'keys', 'kill', 'leading', 'leave',
    'left', 'like', 'limit', 'linear', 'lines', 'load', 'localtime', 'localtimestamp',
    'lock', 'long', 'longblob', 'longtext', 'loop', 'low_priority', 'match',
    'mediumblob', 'mediumint', 'mediumtext', 'middleint', 'minute_microsecond',
    'minute_second', 'mod', 'modifies', 'natural', 'not', 'no_write_to_binlog',
    'null', 'numeric', 'on', 'optimize', 'option', 'optionally', 'or', 'order',
    'out', 'outer', 'outfile', 'precision', 'primary', 'procedure', 'purge',
    'range', 'read', 'reads', 'read_write', 'real', 'references', 'regexp',
    'release', 'rename', 'repeat', 'replace', 'require', 'restrict', 'return',
    'revoke', 'right', 'rlike', 'schema', 'schemas', 'second_microsecond',
    'select', 'sensitive', 'separator', 'set', 'show', 'smallint', 'spatial',
    'specific', 'sql', 'sqlexception', 'sqlstate', 'sqlwarning', 'sql_big_result',
    'sql_calc_found_rows', 'sql_small_result', 'ssl', 'starting', 'straight_join',
    'table', 'terminated', 'then', 'tinyblob', 'tinyint', 'tinytext', 'to',
    'trailing', 'trigger', 'true', 'undo', 'union', 'unique', 'unlock', 'unsigned',
    'update', 'usage', 'use', 'using', 'utc_date', 'utc_time', 'utc_timestamp',
    'values', 'varbinary', 'varchar', 'varcharacter', 'varying', 'when', 'where',
    'while', 'with', 'write', 'xor', 'year_month', 'zerofill'
  ];

  if (RESERVED_WORDS.includes(safeStr.toLowerCase())) {
    safeStr = '_' + safeStr;
  }

  return safeStr;
}



const jsonToSQL = (arrayOfObjects, tableName) => {

  const dataTypeMap = {
    string: 'VARCHAR(255)',
    number: 'NUMERIC',
    boolean: 'BOOLEAN',
    object: 'JSON',
    null: 'VARCHAR(255)', // default to VARCHAR(255) for null
    undefined: 'VARCHAR(255)' // default to VARCHAR(255) for undefined
  };

  const sqlFieldTypes = {};
  const sqlFieldNames = {};
  const usedNames = {};

  const sqlTableName = tableName.replace(/\W+/g, '_').toLowerCase();

  for (const obj of arrayOfObjects) {
    for (const key in obj) {
      const value = obj[key];
      const valueType = typeof value;

      if (!sqlFieldTypes[key] || dataTypeMap[valueType]) {
        sqlFieldTypes[key] = dataTypeMap[valueType];
      }
    }
  }

  // Handle duplicates after collecting field types
  for (const key in sqlFieldTypes) {
    let trimmedKey = toSqlSafeFieldName(key);
    if (!usedNames[trimmedKey]) {
      sqlFieldNames[key] = trimmedKey;
      usedNames[trimmedKey] = true;
    } else {
      let suffix = 1;
      while (usedNames[`${trimmedKey}_${suffix}`]) {
        suffix++;
      }
      sqlFieldNames[key] = `${trimmedKey}_${suffix}`;
      usedNames[`${trimmedKey}_${suffix}`] = true;
    }
  }

  const fieldsOutput = [];
  for (const fieldName in sqlFieldTypes) {
    const fieldType = sqlFieldTypes[fieldName];
    const sqlName = sqlFieldNames[fieldName];
    if (sqlName) {
      fieldsOutput.push({
        [fieldName]: {
          type: fieldType,
          sql_name: sqlName
        }
      });
    }
  }

  const createScript = `CREATE TABLE ${sqlTableName} (\n  ${fieldsOutput.map(field => {
    const fieldName = Object.keys(field)[0];
    const { type, sql_name } = field[fieldName];
    return `${sql_name} ${type}`;
  }).join(',\n  ')}\n);`;

  const inserts = arrayOfObjects.map(obj => {
    const fields = Object.keys(obj).filter(f => obj[f] !== undefined);
    const values = fields.map(field => {
      const value = obj[field];
      if (value === null || value === undefined) {
        return 'NULL';
      }
      if (typeof value === 'string') {
        return `'${value.replace(/'/g, "''")}'`; // Escaping single quotes
      }
      if (typeof value === 'object') {
        return `'${JSON.stringify(value).replace(/'/g, "''")}'`; // Convert object to string and escape
      }
      return value;
    });
    return `INSERT INTO ${sqlTableName} (${fields.map(field => sqlFieldNames[field]).join(', ')}) VALUES (${values.join(', ')});`;
  });

  return {
    fields: fieldsOutput,
    create_script: createScript,
    inserts: inserts,
    id: sqlTableName,
    sql_table_name: sqlTableName
  };
}

export default jsonToSQL;
