
const rebuildData = (result) => {
  let data = [];

  for(var i in result.values){
    let d = {};
    for(var j in result.columns){
      d[result.columns[j]] = result.values[i][j];
    }

    data.push(d);
  }

  return data;
}

export default rebuildData;
