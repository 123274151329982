import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import shortid from 'shortid'

import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'

import 'react-reflex/styles.css'

import {Controlled as CodeMirror} from 'react-codemirror2'

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import CustomField from '../../kit/components/CustomField/CustomField'
import Dropdown from '../../kit/components/Dropdown/Dropdown'
import CustomTable from '../../kit/components/CustomTable/CustomTable'
import Modal from '../../kit/components/Modal/Modal'


import {
  toggleQueryAutoConvert,
  tryToConvertToSQL,
  tryToDescribeSQL,
  tryToRunQuery,
  updateView,
  tryToAddFileToDB,
  newView,
  setHistory,
  addToDashboard,
  setView,
  tryToDesignChart
} from '../../actions/actions.export'

import jsonToSQL from '../../utilities/jsonToSQL'

import './QueryView.scss';

require('codemirror/mode/sql/sql');
require('codemirror/lib/codemirror.css');


class QueryView extends Component {

  constructor(props){
    super(props);

    this.state = {
      queryDescription: "test",
      newTableName: "",
      newTableModal: false
    }

    this.handleConvertToSQL = this.handleConvertToSQL.bind(this);
    this.handleDescribeSQL = this.handleDescribeSQL.bind(this);
    this.handleRunQuery = this.handleRunQuery.bind(this);
  }

  handleConvertToSQL(runImmediately){
    const { dispatch, dashReducer, view } = this.props;

    let description = dashReducer.data.map(d => d.create_script + "\n" + d.table_description).join('\n\n');

    dispatch(tryToConvertToSQL({
      view_id: this.props.view.id,
      table_descriptions: description,
      query_description: this.props.view.query_description,
      runImmediately: runImmediately
    }))
  }


  handleDescribeSQL(){
    const { dispatch, dashReducer, view } = this.props;

    dispatch(tryToDescribeSQL({
      view_id: this.props.view.id,
      table_descriptions: dashReducer.data.map(d => d.create_script).join('\n\n'),
      sql: this.props.view.sql
    }))
  }

  handleRunQuery(){
    const { dispatch, dashReducer } = this.props;

    dispatch(tryToRunQuery({
      view_id: this.props.view.id,
      sql: this.props.view.sql
    }))
  }

  downloadCSV(arrayOfObjects, fileName) {
    if (!arrayOfObjects || !arrayOfObjects.length) {
      return;
    }

    // Convert array of objects to CSV string
    const csvContent = arrayOfObjects.map(row => {
      return Object.values(row).map(val => {
        // Wrap values that contain commas, newlines or double quotes in double quotes
        if (typeof val === 'string' && (val.includes(',') || val.includes('\n') || val.includes('"'))) {
          val = '"' + val.replace(/"/g, '""') + '"';
        }
        return val;
      }).join(',');
    }).join('\n');

    // Add headers
    const headers = Object.keys(arrayOfObjects[0]).join(',') + '\n';
    const csv = headers + csvContent;

    // Convert CSV string to Blob
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // Create a download link and click it
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    link.href = url;
    link.download = fileName.endsWith('.csv') ? fileName : fileName + '.csv';
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Release the blob URL
    URL.revokeObjectURL(url);
  }


  render(){
    const { dispatch, dashReducer, guiReducer } = this.props;

    return  <div className="workspace-section-child-body query-view">
      <Modal
        show={this.state.newTableModal}
        exitable={true}
        onExit={e => this.setState({newTableModal: false, newTableName: ""})}
        cancelable={true}
        onCancel={e => this.setState({newTableModal: false, newTableName: ""})}
        acceptable={true}
        acceptButtonDisabled={!this.state.newTableName}
        acceptButtonLabel="Add Table"
        onAccept={e => {
          let packet = jsonToSQL(this.props.view.data, this.state.newTableName);

          dispatch(tryToAddFileToDB(packet));

          this.setState({
            newTableName: "",
            newTableModal: false
          })
        }}
        content={<div>
          <h4 className="no-margin-top">New Table</h4>
          <CustomField
            label="Table Name"
            placeholder="my_new_table"
            value={this.state.newTableName}
            onChange={e => this.setState({newTableName: e.value})}
            />
        </div>}
        />
      <ReflexContainer orientation="horizontal">
        <ReflexElement className="flex-column-stretch">
          <div className="flex-split padding-1rem reflex-header">
            <div className="list-left">
              <CustomButton
                display={<span><i className="fas fa-play icon-before-text"/>Convert & Run</span>}
                color="blue"
                size="small"
                thinking={dashReducer.tryingToConvertToSQL || dashReducer.tryingToRunQuery}
                disabled={!this.props.view.query_description}
                onClick={() => {
                  this.handleConvertToSQL(true)
                }}
                />

              <CustomButton
                display={<span><i className="fas fa-play icon-before-text"/>Run SQL</span>}
                color="transparent"
                size="small"
                disabled={!this.props.view.sql}
                onClick={this.handleRunQuery}
                />

             
            </div>
            <div className="list-right">
              <CustomButton
                display={<span>Convert to SQL<i className="fas fa-arrow-to-right icon-after-text"/></span>}
                size="small"
                color="transparent"
                disabled={!this.props.view.query_description}
                thinking={dashReducer.tryingToConvertToSQL}
                fail={dashReducer.convertToSQLFail}
                success={dashReducer.convertToSQLSuccess}
                onClick={this.handleConvertToSQL}
                />
              <CustomButton
                display={<span><i className="fas fa-arrow-to-left icon-before-text"/>Describe Query</span>}
                size="small"
                color="transparent"
                disabled={!this.props.view.sql}
                thinking={dashReducer.tryingToDescribeSQL}
                fail={dashReducer.describeSQLFail}
                success={dashReducer.describeSQLSuccess}
                onClick={this.handleDescribeSQL}
                />
              {/*{
                dashReducer.queryAutoConvert ? 
                <CustomButton
                  display={<span><i className="fas fa-toggle-on icon-before-text"/>Auto-Convert is On</span>}
                  color="success"
                  size="small"
                  onClick={e => dispatch(toggleQueryAutoConvert(false))}
                  />
                :
                <CustomButton
                  display={<span><i className="fas fa-toggle-off icon-before-text"/>Auto-Convert is Off</span>}
                  color="transparent"
                  size="small"
                  onClick={e => dispatch(toggleQueryAutoConvert(true))}
                  />
              }*/}
            </div>
          </div>
          <div className="flex-grow relative">
            <ReflexContainer orientation="vertical">  
              <ReflexElement className="flex-column-stretch">
                <span className="padding-1rem text-900">Query Description</span>
                <div className="flex-grow relative">
                  <textarea 
                    className="input-full"
                    placeholder="describe your query in simple terms here"
                    value={this.props.view.query_description}
                    onChange={e => dispatch(updateView({id: this.props.view.id, query_description: e.target.value}))}

                    onBlur={e => {
                      if(dashReducer.queryAutoConvert) this.handleConvertToSQL();
                    }}
                    />
                </div>
              </ReflexElement>
              <ReflexSplitter/>
              <ReflexElement className="flex-column-stretch">
                <span className="padding-1rem text-900">SQL Query Editor</span>
                <div className="flex-grow relative">
                  <CodeMirror 
                    value={this.props.view.sql}
                    onBeforeChange={(editor, data, value) => {
                      dispatch(updateView({id: this.props.view.id, sql: value}));
                    }}
                    options={{
                      lineNumbers: true,
                      mode: 'sql',
                      lineWrapping: true,
                    }}
                    onBlur={e => {
                      if(dashReducer.queryAutoConvert) this.handleDescribeSQL();
                    }}
                    />
                </div>
              </ReflexElement>
            </ReflexContainer>
          </div>
        </ReflexElement>
        <ReflexSplitter/>
        {
          this.props.view.data &&
        
          <ReflexElement className="flex-column-stretch">
            <div className="flex-split padding-1rem reflex-header">
              <div className="list-left">
                <span><span className="text-900">Query Results</span> ({(this.props.view.data || []).length} rows)</span>
              </div>
              <div className="list-right">
                <CustomButton
                  color="transparent"
                  display={<span><i className="far fa-arrow-down icon-before-text"/>Download CSV</span>}
                  onClick={()=>{
                    this.downloadCSV(this.props.view.data, 'query_' + new Date().toISOString() + '.csv')
                  }}
                  size="small"
                  />
                <CustomButton
                  disabled={this.props.view.error}
                  color="transparent"
                  display={<span><i className="far fa-layer-plus icon-before-text"/>Store Results as New Table</span>}
                  onClick={()=>{
                    this.setState({ newTableModal: true })
                  }}
                  size="small"
                  />

                <Dropdown
                  target={
                  <CustomButton
                    disabled={this.props.view.error}
                    color="blue"
                    display={<span><i className="far fa-chart-bar icon-before-text"/>Add Results to Dashboard<i className="far fa-angle-down icon-after-text"/></span>}
                    size="small"
                    />
                  }
                  items={[
                      dashReducer
                        .views
                        .filter(v => v.mode === 'dashboard')
                        .map(v => <span onClick={e => { 
                          dispatch(addToDashboard({
                            id: v.id, 
                            // data: this.props.view.data, 
                            result: this.props.view.result,
                            sql: this.props.view.sql,
                            query_description: this.props.view.query_description,
                            mode: 'table'
                          })) 
                          dispatch(setView(v.id))
                          dispatch(tryToDesignChart({
                            view_id: v.id,
                            chart_id: v.layout[v.layout.length - 1].i,
                            data: this.props.view.data
                          }))
                          dispatch(setHistory());
                        }}>
                          <i className="fal fa-tachometer text-primary fa-fw icon-before-text"/>{v.display_name}
                        </span>),
                      dashReducer
                        .views
                        .filter(v => v.mode === 'dashboard').length > 0 ? 'divider' : undefined,
                      <span  onClick={() => {
                          let chart_id = shortid.generate();
                          dispatch(newView({
                            mode: 'dashboard', 
                            layout: [
                              {
                                i: chart_id,
                                x: 0,
                                y: 0,
                                w: 6,
                                h: 8,
                                // data: this.props.view.data,
                                result: this.props.view.result,
                                sql: this.props.view.sql,
                                query_description: this.props.view.query_description
                              }
                            ]
                          }));
                          dispatch(tryToDesignChart({
                            view_id: dashReducer.views[dashReducer.views.length - 1].id,
                            chart_id: chart_id,
                            data: this.props.view.data
                          }))
                          dispatch(setHistory());
                        }}><i className="fal fa-plus text-primary fa-fw icon-before-text"/>New Dashboard</span>
                    ]
                  }
                  />
              </div>
            </div>
            <div className="flex-grow relative scroll-parent">
              <div className="scroll-child">
                {
                  this.props.view.result &&
                  <CustomTable
                    headers={this.props.view.result.columns.map(h => {
                      return {
                        name: h,
                        display: h,
                        getter: (r, i) => <div className="text-ellipsis-parent"><span>{r[h]}</span></div>,
                        sortGetter: (r, i) => r[h]
                      }
                    })}
                    rawData={this.props.view.data || []}
                    />
                }
                {
                  this.props.view.error &&
                  <div className="text-danger">
                    <span className="text-900">Error: </span> {this.props.view.error}
                  </div>
                }
              </div>
            </div>
          </ReflexElement>
        }
      </ReflexContainer>                          
    </div>
  }
}


const mapStateToProps = (state) => {
  const { guiReducer, dashReducer } = state;

  return {
    guiReducer,
    dashReducer
  }
}

export default connect(mapStateToProps)(QueryView);

  