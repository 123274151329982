import Cookies from 'js-cookie';
import store from 'store';
import { push } from 'react-router-redux';
import addCookieWithConsent from '../utilities/addCookieWithConsent';

import {
  dismissToast,
  loadChatHistory,
  tryToAddFileToDB,
  loadDashHistory
} from './actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Set & Load History
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const clearHistory = () => {
  store.remove('history');
  window.location.reload();
}


export const setHistory = packet => (dispatch, getState) => {



  const chatReducer = getState().chatReducer;
  const dashReducer = getState().dashReducer;

  let data = {
    messages: chatReducer.messages,
    // dashboards: chatReducer.dashboards,
    usage: chatReducer.usage,
    db: dashReducer.data,
    views: dashReducer.views,
    currentView: dashReducer.currentView
  }

  for(var i in data.views){
    if(data.views[i].mode === 'table'){
      delete data.views[i].data;
      delete data.views[i].result;
    }
  }

  for(var i in data.db){
    delete data.db[i].data;
    delete data.db[i].result;
  }

  // console.log('history set', data);

  try{
    store.set('history', data);
  } catch(e){
    console.error(e);
  }
  
   return {
    type: 'SET_HISTORY',
    data: packet
  }
}

export const tryToLoadHistory = packet => (dispatch, getState) => {

  let data = store.get('history');

  console.log('loading history', data);

  if(data){
    dispatch(loadChatHistory({
      messages: data.messages,
      usage: data.usage
    }));
    for(var i in data.db){
      dispatch(tryToAddFileToDB(data.db[i]));
    }
    dispatch(loadDashHistory({
      views: data.views || [],
      currentView: data.currentView
    }))
  } else {
    dispatch(setHistory());
  }
}


