import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import tooltip from '../kit/reducers/reducers.tooltip.js';
import toast from '../kit/reducers/reducers.toast.js';

import { guiReducer } from './reducers.gui.js';
import { dashReducer } from './reducers.dash.js';
import { chatReducer } from './reducers.chat.js';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  tooltip,
  toast,
  dashReducer,
  guiReducer,
  chatReducer
  // 
})
export default rootReducer