import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'

import 'react-reflex/styles.css'

import {Controlled as CodeMirror} from 'react-codemirror2'

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import CustomField from '../../kit/components/CustomField/CustomField'
import CustomTable from '../../kit/components/CustomTable/CustomTable'
import TabNav from '../../kit/components/TabNav/TabNav'
import Callout from '../../kit/components/Callout/Callout'


import {
  updateView,
  updateData,
  setHistory,
  tryToRunQuery
} from '../../actions/actions.export'

import './TableView.scss';

require('codemirror/mode/sql/sql');
require('codemirror/lib/codemirror.css');

class TableView extends Component {

  constructor(props){
    super(props);

    this.state = {
      queryDescription: "test",
      pageSize: 15,
      currentPage: 0,
      currentTab: 'preview'
    }

    this.dataCheck = this.dataCheck.bind(this);
  }

  componentDidUpdate(){
    this.dataCheck();
  }

  componentDidMount(){
    this.dataCheck();
  }


  dataCheck(){
    const { dispatch, dashReducer, guiReducer } = this.props;

    const view = dashReducer.views.find(v => v.id === this.props.view.id);

    if(!view.data && this.state.currentTab === 'preview'){
      dispatch(tryToRunQuery({
        view_id: this.props.view.id,
        sql: 'select * from ' + this.props.view.table
      }))
    }
  }


  render(){
    const { dispatch, dashReducer, guiReducer } = this.props;

    const data = dashReducer.data.find(d => d.sql_table_name === this.props.view.table);

    const view = dashReducer.views.find(v => v.id === this.props.view.id);

    return  <div className="workspace-section-child-body table-view flex-column-stretch" key={data.id}>
      <div className="flex-split ">

        <TabNav
          value={this.state.currentTab}
          onChange={e => this.setState({currentTab: e})}
          items={[
              {
                display: <span><i className="far fa-table icon-before-text text-very-small text-themed-muted"/>Table Preview</span>,
                value: "preview"
              },
              {
                display: <span><i className="far fa-code icon-before-text text-very-small text-themed-muted"/>Create Script</span>,
                value: "create"
              },
              {
                display: <span><i className="far fa-pencil icon-before-text text-very-small text-themed-muted"/>Edit Table</span>,
                value: "edit"
              }
            ]}
          />
        <div className="list-right margin-right-1rem">
          {
            (this.state.currentTab === 'preview' && view.data) &&
            <div className="list-right">
              <span className="text-themed-muted">
                Showing {this.state.currentPage * this.state.pageSize + 1} to {view.data.length < (this.state.currentPage + 1) * this.state.pageSize ? (view.data.length).toLocaleString() : (this.state.currentPage + 1) * this.state.pageSize} of {view.data.length.toLocaleString()}
              </span>
              <CustomButton
                size="small"
                display="Prev"
                color="grey"
                disabled={this.state.currentPage === 0}
                onClick={()=>{
                  this.setState({
                    currentPage: this.state.currentPage - 1
                  })
                }}
                />
              <CustomButton
                size="small"
                display="Next"
                color="grey"
                disabled={(this.state.currentPage + 1) * this.state.pageSize > view.data.length}
                onClick={()=>{
                  this.setState({
                    currentPage: this.state.currentPage + 1
                  })
                }}
                />
            </div>
          }

         {/* <CustomButton
            display={<span>Delete Table<i className="fas fa-times icon-after-text"/></span>}
            size="small"
            color="transparent-danger"
            thinking={dashReducer.tryingToConvertToSQL}
            fail={dashReducer.convertToSQLFail}
            success={dashReducer.convertToSQLSuccess}
            onClick={this.handleConvertToSQL}
            />*/}
        </div>
      </div>

      {
        this.state.currentTab === 'preview' &&
        <div className="flex-column-stretch flex-grow">
          
          <div className="flex-split reflex-header">
            <div className="list-left">
              
            </div>
            
          </div>
          <div className="flex-grow relative scroll-parent">
            <div className="scroll-child">
              {
                (view.data && view.result) &&
                <CustomTable
                  pageSize={this.state.pageSize}
                  hidePagination={true}
                  currentPage={this.state.currentPage}
                  headers={view.result.columns.map(h => {
                    return {
                      name: h,
                      display: h,
                      getter: (r, i) => <div className="text-ellipsis-parent"><span>{r[h]}</span></div>,
                      sortGetter: (r, i) => r[h]
                    }
                  })}
                  rawData={view.data || []}
                  />
              }
            </div>
          </div>
        </div>
      }


      {
        this.state.currentTab === 'create' &&
        <div className="flex-column-stretch flex-grow">
          
          <div className="flex-split reflex-header">
            <div className="list-left">
            </div>
            <div className="list-right">
            </div>
          </div>

          <div className="flex-grow relative scroll-parent">
            <div className="scroll-child">  
              <CodeMirror 
                value={data.create_script}
                disabled={true}
                options={{
                  lineNumbers: true,
                  mode: 'sql',
                  disabled: true,
                  lineWrapping: true
                }}
                />
            </div>
          </div>
        </div>
      }

      {
        this.state.currentTab === 'edit' &&
        <div className="flex-column-stretch flex-grow">
          
          <div className="flex-split reflex-header">
            <div className="list-left">
            </div>
            <div className="list-right">
            </div>
          </div>

          <div className="flex-grow relative scroll-parent">
            <div className="scroll-child padding-2rem">  
              <div className="row">
                <div className="col-md-6">
                  <CustomField
                    label="Table Description"
                    value={data.table_description}
                    disabled={dashReducer.tryingToDescribeTable[this.props.view.table]}
                    onChange={e => dispatch(updateData({id: data.id, table_description: e.value}))}
                    rows={10}
                    placeholder={dashReducer.tryingToDescribeTable[this.props.view.table] ? "generating description..." : "Describe what kind of information is in this table"}
                    description="This information helps the AI understand how to navigate and interact with the data."
                    />
                </div>
                <div className="col-md-6">
                  <div className="next-to-custom-field-spacer"/>
                  <Callout
                    title="Tip:"
                    content={
                      <div>
                        <p>When uploading a file or creating a new table, an initial description will be drafted based on what the AI can interpret from the data itself.</p>
                        <p>Along with the table's CREATE script, this information is accessible to the AI and using it to explain details or quirks about the dataset can greatly improve performance.</p>
                      </div>
                      }
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
{/*
      <ReflexContainer orientation="horizontal">
        <ReflexElement className="flex-column-stretch">
          
          <div className="flex-grow relative">
            <ReflexContainer orientation="vertical">  
              <ReflexElement>
                <span className="top-right-label">{dashReducer.tryingToDescribeTable[this.props.view.table] && <i className="fal fa-spinner-third fa-spin icon-before-text"/>}Table Description</span>
                <textarea 
                  className="input-full"
                  value={data.table_description}
                  onChange={e => dispatch(updateData({id: data.id, table_description: e.target.value}))}
                  onBlur={e => dispatch(setHistory())}
                  />
              </ReflexElement>
              <ReflexSplitter/>
              <ReflexElement className="locked-codemirror-wrapper">
                <span className="top-right-label">SQL CREATE</span>
                <CodeMirror 
                  value={data.create_script}
                  disabled={true}
                  options={{
                    lineNumbers: true,
                    mode: 'sql',
                    disabled: true,
                    lineWrapping: true
                  }}
                  />
                
              </ReflexElement>
            </ReflexContainer>
          </div>
        </ReflexElement>
        <ReflexSplitter/>
          
      </ReflexContainer>    */}                      
    </div>
  }
}


const mapStateToProps = (state) => {
  const { guiReducer, dashReducer } = state;

  return {
    guiReducer,
    dashReducer
  }
}

export default connect(mapStateToProps)(TableView);

  