import {
  setHistory,
  addToast
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Convert SQL to text
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestDescribeSQL = packet => {
  return {
    type: 'REQUEST_DESCRIBE_SQL',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveDescribeSQLSuccess = data => ({
  type: 'RECEIVE_DESCRIBE_SQL_SUCCESS',
  data
})

export const receiveDescribeSQLFail = data => ({
  type: 'RECEIVE_DESCRIBE_SQL_FAIL',
  data
})

export const tryToDescribeSQL = packet => (dispatch, getState) => {

  dispatch(requestDescribeSQL(packet));


  let headers = {
    "Content-Type": "application/json"
  }

  return fetch('/api/dash/describe-sql', {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveDescribeSQLSuccess({
        view_id: packet.view_id,
        response: json
      }));
      dispatch(setHistory());
    })
    .catch(e => {
      
      dispatch(receiveDescribeSQLFail({errors:e, lastSubmit: packet}));
      dispatch(addToast({
        title: "Failed to Respond",
        // detail: e.error || e,
        autoDismiss: 5000
      }))
    })
}
