import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FileUploader } from "react-drag-drop-files";
import shortid from 'shortid'

import './Filez.scss';

import CustomButton from '../../kit/components/CustomButton/CustomButton'

import {
  tryToAddFileToDB,
  setHistory,
  toggleFileUploader,
  setView
} from '../../actions/actions.export'

import jsonToSQL from '../../utilities/jsonToSQL'

const csvToJson = require('csvtojson')

class Filez extends Component {
  constructor(props){
    super(props)

    this.state = {
      files: [],
      thinking: false
    }

    this.handleChange = this.handleChange.bind(this);
  }


  handleChange(files){
    this.setState({thinking: files.length});

    for(var f in files){
      let file = files[f];
      if(file.name && file.name.toLowerCase().trim().endsWith('.csv')){
        this.setState({thinking: this.state.thinking + 1});
        try{
          const reader = new FileReader()

          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {

            const binaryStr = reader.result;
            csvToJson({
                // delimiter: [",", ", "],
                flatKeys: true
              })
              .fromString(binaryStr)
              .then((jsonObj) => {
                console.log(jsonObj);

                let sqlVersion = jsonToSQL(jsonObj, file.name.replace('.csv', ''));
                sqlVersion.file = file;

                let datas = this.state.files;
                datas.push(sqlVersion);

                this.setState({
                  files: datas,
                  thinking: this.state.thinking - 1
                })
              })
          }
          reader.readAsText(file);
        } catch (e){
          console.error(e);
        }
      }
    }
  }

  render(){
    const { dispatch, dashReducer } = this.props;

    return <div className={"filez"}>
      <div className="box box-extra-pad">
        
        <div className="file-uploader-wrapper">
          <FileUploader 
            handleChange={this.handleChange} 
            name="file" 
            multiple={true}
            label={"Drag & drop or click here to upload files"}
            types={['CSV']} 
            maxSize={10}
            />
        </div>

        {
          this.state.files.map((f, i) => {
            return <div key={i} className="filez-file">
              <div className="flex-split">
                <div>
                  <span className="text-700">{f.file.name}</span><br/>
                  <small className="text-semi-muted">{f.inserts.length} rows and {f.fields.length} fields detected</small>
                </div>
                <i className="fal fa-times text-hover-danger clickable" onClick={e => {
                  let files = this.state.files;
                  files.splice(i, 1);
                  this.setState({
                    files: files
                  })
                }}/>
              </div>
            </div>
          })
        }

        {
          this.state.files.length > 0 || dashReducer.data.length > 0  ?
          <div className="flex-column-center margin-top-3rem">
            <CustomButton
              color="success"
              display="Launch DashGPT"
              onClick={e => {
                // create 
                for(var i in this.state.files){
                  dispatch(tryToAddFileToDB(this.state.files[i]));
                }
                dispatch(setHistory());
                dispatch(toggleFileUploader(false));
                // setTimeout(()=>{
                //   dispatch(setView(dashReducer.views[0].id));
                // })
              }}
              />
          </div>
          :

          ( dashReducer.data.length === 0 ? 


            (
              this.state.thinking > 0 ?
              <h5 className="text-center no-margin-bottom margin-top-2rem"><i className="fal fa-spinner-third fa-spin icon-before-text"/>processing...</h5>
              :
              <h5 className="text-center no-margin-bottom margin-top-2rem">Upload data to get started</h5>
            )
            :
            <h5 className="text-center no-margin-bottom margin-top-2rem">Select more files</h5>
          )
        }

      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { dashReducer } = state;

  return {
    dashReducer
  }
}

export default connect(mapStateToProps)(Filez);

  