import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Prompt } from 'react-router-dom'
import { push } from 'react-router-redux';
import moment from 'moment'
import shortid from 'shortid'
import ReactJson from 'react-json-view'

import CustomField from 'kit/components/CustomField/CustomField.js'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js'
import CustomButton from 'kit/components/CustomButton/CustomButton.js'
import Modal from 'kit/components/Modal/Modal.js'
import Dropdown from 'kit/components/Dropdown/Dropdown.js'

import ChatTypewriter from './ChatTypewriter'
import './Chat.scss';

class Chat extends Component {
  constructor(props){
    super(props);


    this.state = {
      showBackOfHouse: false,
      input: "",
      messages: props.initialData || []
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleSendMessage(e){
    if(e && e.preventDefault) e.preventDefault();

    if(this.state.input.trim().length > 0){

      this.props.onMessageSend(this.state.input.trim())

      this.setState({
        input: ""
      })
    }
  }

  handleKeyDown(e){
    const { dispatch } = this.props;

    if(e.key === "Enter" && !e.shiftKey){
      this.handleSendMessage();
    }
  }


  handleKeyUp(e){
    const { dispatch } = this.props;

    if(e.key === "Enter" && !e.shiftKey){
      // this.handleSendMessage();
      this.setState({
        input: ""
      })
    }
  }


  componentDidUpdate(){
    var objDiv = document.getElementById("custom-chat-convo");
    if(objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  }


  componentDidMount(){
    var objDiv = document.getElementById("custom-chat-convo");
    if(objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  }

  handleInputChange(e){
    let value = e.target.value;

    this.setState({
      input: value
    })
  }

  render(){

    const { content } = this.props;

    const messages = this.props.messages || [];

    let mostRecentMessage = messages[messages.length - 1];

    return <div className="custom-chat">
      <div className="custom-chat-column flex-grow">
        
        <div className="custom-chat-inner">
          <div className="custom-chat-convo" id="custom-chat-convo">
            {
              messages.map((m, i)=>{

                let doubleSend = false;
                if(i > 0){
                  if(m.role == messages[i-1].role && m.timestamp - messages[i-1].timestamp < 1000*60){
                    doubleSend = true;
                  }
                }

                let match = (this.props.renderRules || []).find(r => r.rule(m));
                
                if(match){
                  return <div key={i} className="custom-chat-row custom-chat-row-topic-change">
                    <div className="custom-chat-row-topic-change-hr"/>
                      <div className="custom-chat-row-topic-change-details">
                        {match.rule(m)}<br/>
                      </div>
                    <div className="custom-chat-row-topic-change-hr"/>
                  </div>
                }


                return <div key={i} className="custom-chat-row-wrapper">
                  <div className={"custom-chat-row " + (m.role ==  "assistant" ? "custom-chat-row-bot" : "custom-chat-row-user")}>
                    <div className={"custom-chat-message " + (m.role ==  "assistant" ? "custom-chat-message-bot" : "custom-chat-message-user")}>
                      
                      
                      <pre className={"custom-chat-message-body " + (doubleSend ? "custom-chat-message-body-double-send" : "") }>
                        {
                          (m.role === "assistant" && i === messages.length -1 && moment().valueOf() - moment(m.timestamp).valueOf() < 60000) ? 
                          <ChatTypewriter text={m.content && m.content.trim()} delay={50} onStart={()=>{ this.setState({animating: true})}} onComplete={()=>{ this.setState({animating: false}) }}/>
                          :
                          (m.content && m.content.trim())
                        }
                        {m.content.html && m.content.html}
                      </pre>
                    </div>
                    
                  </div>
                  <div className={"custom-chat-row " + (m.role ==  "assistant" ? "custom-chat-row-bot" : "custom-chat-row-user")}>
                    <div className={"flex-grow " + (m.role ==  "assistant" ? "list-left" : "list-right")}>
                      
                      {
                        (m.role === 'user' && m.failed) && <small className="text-danger"><i className="fal fa-exclamation-triangle"/> Failed to Respond</small>
                      }
                      
                      <Dropdown
                        align={m.role === "user" ? 'right' : 'left'}
                        items={[
                            
                            m.failed ? <span 
                              onClick={()=>{
                                this.props.processData(this.state.messages);
                              }}
                              className="text-primary"
                              >
                              <div className="flex-split">
                                <span>Resend Message</span><i className="fal fa-paper-plane"></i>
                              </div>
                            </span> : undefined
                            ,
                            m.failed ? "divider" : undefined
                            ,
                            <span 
                              className=""
                              onClick={()=>{
                                navigator.clipboard.writeText(m.content);
                              }}>
                              <div className="flex-split">
                                <span>Copy text</span><i className="fal fa-copy"></i>
                              </div>
                            </span>,

                            <span
                              className=" text-hover-danger"
                              onClick={()=>{
                                this.props.onDeleteMessage(m,i);
                              }}
                              >
                              <div className="flex-split">
                                <span>Delete Message</span><i className="fal fa-times"></i>
                              </div>
                            </span>,
                            'divider',
                            <span
                              className=" text-hover-danger"
                              onClick={()=>{
                                this.props.onDeleteAllMessages(m,i);
                              }}
                              >
                              <div className="flex-split">
                                <span>Erase Entire Conversation</span><i className="fal fa-trash"></i>
                              </div>
                            </span>
                          ]}
                        target={
                            <div>
                              <span className="custom-chat-message-time">{moment(m.timestamp).format('h:mm a')}</span> <i className="far fa-xs fa-fw fa-angle-down"></i>
                            </div>
                          }
                        />
                      
                      {
                        (m.role === 'assistant' && m.failed) && <small className="text-danger"><i className="fal fa-exclamation-triangle"/> Failed to Respond</small>
                      }
                    </div>
                  </div>
                </div>
              })
            }


            {
              this.props.thinking && <div className={"custom-chat-row custom-chat-row-bot"}>
                <div className={"custom-chat-message custom-chat-message-bot"}>
                  <pre className={"custom-chat-message-body"}>
                    <i className="far fa-spinner-third fa-spin text-muted"/>
                  </pre>
                </div>
              </div>
            }
          </div>
          <form onSubmit={this.handleSendMessage} className="custom-chat-form">
            <textarea
              id="custom-chat-input"
              className="custom-chat-input"
              onChange={this.handleInputChange}
              value={this.state.input}
              placeholder={this.props.placeholder || "type your message here"}
              onKeyDown={this.handleKeyDown} 
              onKeyUp={this.handleKeyUp} 
              autoFocus
            />
            <button 
              submit="true"
              className={this.state.input.trim().length === 0 ? "button-disabled" : ""}
              >
              <i className="fas fa-arrow-up"/>
            </button>
          </form>
         
        </div> 
      </div>     
    </div>
  }
}


const mapStateToProps = (state) => {
  const {  } = state;

  return {
    
  }
}

export default connect(mapStateToProps)(Chat);
