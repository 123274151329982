import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './TabNav.css';

class TabNav extends Component {
  _isMounted = false;

  constructor(props){
    super(props);

  }


  componentDidUpdate(){

  }

  render(){
    let classList = "";

    if(this.props.vertical){
      classList += " tab-nav-vertical";
    } else {
      classList += " tab-nav-horizontal";
    }

    if(this.props.selectedStaysLit || this.props.vertical){
      classList += " tab-nav-selected-stays-lit";
    }

    if(this.props.grow){
      classList += " tab-nav-grow";
    } 

    if(this.props.borderless || true){
      classList += " tab-nav-borderless";
    } 

    return <div className={"tab-nav " + classList}>
      {
        this.props.items.map((item, i)=>{

          if(item.hideIf) return;

          let itemClassList = "tab-nav-item";
          if(this.props.value === item.value) itemClassList += " tab-nav-item-selected";
          if(item.disabled) itemClassList += " tab-nav-item-disabled";

          if(this.props.vertical){
            return <div 
              key={i} 
              className={itemClassList}
              onClick={()=>{
                this.props.onChange(item.value);
              }}
              >
              <nobr className="list-left">
                {
                  item.autoIcon &&
                  <i className={item.autoIcon + " fa-fw tab-nav-item-icon"}/>
                }
                <span className="text-ellipsis no-margin-right tab-nav-item-text">{item.display}</span>
              </nobr>
            </div>
          }
          return <div 
            key={i} 
            className={itemClassList}
            onClick={()=>{
              this.props.onChange(item.value);
            }}
            >
            <nobr className="list-left tab-nav-item-width-setter">
              {
                item.autoIcon &&
                <i className={item.autoIcon + " fa-fw tab-nav-item-icon"}/>
              }
              <span className="text-ellipsis no-margin-right tab-nav-item-text">{item.display}</span>
            </nobr>
            <nobr className="list-left tab-nav-item-display">
              {
                item.autoIcon &&
                <i className={item.autoIcon + " fa-fw tab-nav-item-icon"}/>
              }
              <span className="text-ellipsis no-margin-right tab-nav-item-text">{item.display}</span>
            </nobr>
          </div>
        })
      }
    </div>
  }
};

export default TabNav;