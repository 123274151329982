import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as d3 from 'd3'
import moment from 'moment'

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import CustomField from '../../kit/components/CustomField/CustomField'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect'
import CustomTable from '../../kit/components/CustomTable/CustomTable'
import Dropdown from '../../kit/components/Dropdown/Dropdown'

import {
  updateView,
  deleteChart,
  newView,
  setHistory,
  updateChart,
  showTooltip,
  hideTooltip,
  setEditingChart,
  sendChatMessage
} from '../../actions/actions.export'

import './DashboardChart.scss';


import rebuildData from '../../utilities/rebuildData'
import categorizeValues from '../../utilities/categorizeValues'


let width, height;

const getColor = (index) => {

  let colors = [
    '#6FD1EF', '#faaa35', '#7851a9', '#33c996', '#D24128', '#ea596e', '#027CFE', "#FFE03F"
  ];

  if(index !== undefined) return colors[index];
}


class DashboardChart extends Component {

  constructor(props){
    super(props);

    this.state = {
      mode: props.chart.mode,
      title: "",
      description: "",
      xAxisWordWraps: 0,
      yAxisMaxWidth: 0,
      fields: [],
      resizeCounter: 0,
      dataGroups: []
    }

    this.update = this.update.bind(this);
  }


  componentDidMount(){

    const { chart } = this.props;

    let data = rebuildData(chart.result);

    let fields = chart.result.columns.map(c => {

      let type = categorizeValues(data.map(d => d[c]));

      return {
        name: c,
        type: type,
        value: c,
        label: <span>
          {type === 'number' && <i className="fal fa-tally fa-fw icon-before-text"/>}
          {type === 'string' && <i className="fal fa-text fa-fw icon-before-text"/>}
          {type === 'date' && <i className="fal fa-calendar fa-fw icon-before-text"/>}
          {c}
        </span>
      }
    });

    this.setState({
      fields: fields
    })

    var el = document.getElementById('dashboard-chart-svg-wrapper-' + chart.i);
    if(!el) return;

    var positionInfo = el.getBoundingClientRect();
    width = positionInfo.width;
    height = positionInfo.height;

    var svg = d3.select('#dashboard-chart-svg-' + chart.i)
      .attr('width', width)
      .attr('height', height)
      ;

    setTimeout(()=>{
      this.update();  
    }, 10)

    window.addEventListener('resize', this.update);


  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update);
  }


  componentWillReceiveProps(newprops){
    if(newprops.resizeCounter !== this.state.resizeCounter) setTimeout(this.update, 250);

    this.setState({
      resizeCounter: newprops.resizeCounter
    });

    setTimeout(this.update);
  }


  // componentDidUpdate(){
  //   this.update();
  // }

  update(){

    var that = this;

    const { dispatch, chart } = this.props;

    let data = rebuildData(chart.result);
  
    if(!data || data.length === 0) return;

    let dataGroups = [];
    let y_axis_array = [];

    if(chart.split_data_by){
      for(var i in data){
        let dataGroupIndex = dataGroups.findIndex(d => d[0].split_key === data[i][chart.split_data_by]);
        let newData = {
          split_key: data[i][chart.split_data_by],
          x: data[i][chart.x_axis],
          y: data[i][chart.y_axis],
          radius: data[i][chart.radius],
          color: data[i][chart.color],
          og_data: data[i]
        }
        if(dataGroupIndex > -1){
          dataGroups[dataGroupIndex].push(newData);
        } else {
          dataGroups.push([newData])
        }
      }
    } else {
      y_axis_array = chart.y_axis;
      if(!Array.isArray(y_axis_array)) y_axis_array = [y_axis_array];

      for(var y in y_axis_array){

        let d = [];
        for(var i in data){
          
          d.push({
            split_key: y_axis_array[y],
            x: data[i][chart.x_axis],
            y: data[i][y_axis_array[y]],
            radius: data[i][chart.radius],
            color: data[i][chart.color],
            og_data: data[i]
          })
        }

        dataGroups.push(d);
      }
    }

    this.setState({
      dataGroups: dataGroups
    })


    let el = document.getElementById('dashboard-chart-svg-' + chart.i);
    
    if(!el) return;


    let positionInfo = el.getBoundingClientRect();
    width = positionInfo.width;
    height = positionInfo.height - 2.5; // TODO: dunno why, ill fix this later

    let svg = d3.select('#dashboard-chart-svg-' + chart.i)
      .attr('width', width)
      .attr('height', height)
      ;

    // burn it all down and reset it
    svg.selectAll('g').remove();
    let drawGroup = svg.append('g');

    let padding = {
      top: 5,
      bottom: 5,
      left: 1,
      right: 1
    }


    function wrap(text, width){
      let maxCount = 0;
      text.each(function(e) {
        let count = 0;
        var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            y = text.attr("y"),
            dy = parseFloat(text.attr("dy")),
            tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            count++;
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
          }
        }

        if(count > maxCount) maxCount = count;
      });
      setTimeout(()=>{

        if(that.state.xAxisWordWraps !== maxCount){
          that.setState({
            xAxisWordWraps: maxCount
          })  
        }
      }, 100)
    } 

    function autoGenerateAxis(data, set_type, fields, range, stack_logic){

      if(!fields) return;

      if(!Array.isArray(fields)) fields = [fields];

      fields = fields.map(f => that.state.fields.find(d => d.name === f));

      if(!stack_logic) stack_logic = 'max'; // either max or total for stacked vs grouped. default to group-style (max)

      let min; //= Number.MAX_VALUE;
      let max; // = Number.MIN_VALUE;
      let scale;

      if(fields.length == 0) return;

      let type = fields[0].type;
      // check if there is a field type mismatch and default to string if so
      let hasMixedTypes = fields.find(f => f.type !== type);
      if(hasMixedTypes) type = 'string';

      type = set_type || type;

      if(type === 'date'){
          
        for(var i in fields){
          
          for(var d in data){
            let v = data[d][fields[i].name];
            if(v < min) min = v;
            if(v > max) max = v;
            if(!min) min = v;
            if(!max) max = v;
          }
        }

        scale = d3.scaleTime()
          .domain([new Date(min), new Date(max)]) 
          .range(range)
          .nice()
          ;

      } else if(type === 'string'){

        let options = [];

        for(var i in fields){
          
          for(var d in data){
            options.push(data[d][fields[i].name] + "");
          }
        }


        scale = d3.scaleBand()
          .domain([... new Set(options)]) 
          .range(range)
          ;
      } else {
        let total = 0;
        for(var i in fields){
          for(var d in data){
            let v = parseFloat(data[d][fields[i].name]);
            if(v < min) min = v;
            if(v > max) max = v;
            if(!min) min = v;
            if(!max) max = v;
            total += v;
          }
        }

        if(stack_logic === 'total'){
          max = total;
        }

        // if(stack_logic === 'total'){
        //   min = 0;
        //   let combined_max = 0;
        // for(var i in fields){
        //   for(var d in data){
        //     combined_max += d3.max(datas[i].data, e => e.y);
        //   }
        //   max = combined_max;
        // }

        scale = d3.scaleLinear()
          .domain([min, max]) 
          .range(range)
          ;
      } 

      return scale;
    }



    if(chart.chart_type === 'horizontal-bar'){
      let x_scale = autoGenerateAxis(data, chart.x_axis_type, chart.x_axis, [height - padding.bottom, padding.top]);
      let y_scale = autoGenerateAxis(data, 'number', chart.y_axis, [padding.left, width - padding.right - padding.left], 'total');

      if(!chart.x_axis && !chart.y_axis) return;

      if(y_scale.domain()[0] > 0) y_scale.domain([0, y_scale.domain()[1]]);

      let barSVG = drawGroup.selectAll('bars')
        .data(data)
        .enter()
        .append('g')
        .attr('transform', function(d, i){ return 'translate(' + padding.left + ',' + (15 + 45 * i + padding.top) + ')'} )
        ;

      barSVG.append('text')
        .text(function(d, i){ 
          return d[chart.x_axis] + ': ' + d[chart.y_axis];
        })
        .attr('class', 'dashboard-chart-label')
        ;

      barSVG.append('rect')
        .attr('x', 0)
        .attr('y', 7)
        .attr('width', function(d,i){ 
          return width - padding.left - padding.right 
        })
        .attr('rx', 8)
        .attr('ry', 8)
        .attr('height', 16)
        .attr('class', 'bg-super-light')
        ;

      barSVG.append('rect')
        .attr('x', 0)
        .attr('y', 7)
        .attr('width', function(d,i){ 
          let retval = y_scale(d[chart.y_axis]);
          if(retval < 16) retval = 16;
          return retval;
        })
        .attr('rx', 8)
        .attr('ry', 8)
        .attr('height', 16)
        .attr('fill', function(d, i){
          return getColor(i)
        })
        ;
    }



    if(chart.chart_type === 'vertical-bar' || chart.chart_type === 'stacked-bar'){
      padding = {
        top: 10,
        bottom: 15 + this.state.xAxisWordWraps * 12,
        left: 5 + this.state.yAxisMaxWidth,
        right: 5
      }

      let x_axis_type = chart.x_axis_type || (that.state.fields.find(f => f.name === chart.x_axis) || {}).type;
      let y_axis_type = 'number'; //chart.y_axis_type || (that.state.fields.find(f => f.name === chart.y_axis) || {}).type;

      let longestDataGroupLength = 0;
      for(var i in dataGroups){
        if(dataGroups[i].length > longestDataGroupLength) longestDataGroupLength = dataGroups[i].length;
      }

      if(chart.x_axis_sort){

        let sortBy = chart.x_axis_sort === 'measure' ? chart.y_axis : chart.x_axis;
        data.sort((a,b)=>{
          if(chart.x_axis_sort_direction === "desc"){
            if(a[sortBy] < b[sortBy]) return 1;
            if(a[sortBy] > b[sortBy]) return -1;
          } else {
            if(a[sortBy] < b[sortBy]) return -1;
            if(a[sortBy] > b[sortBy]) return 1;
          }
        })
      }


      let x_scale; 
      let y_scale;

      // before we mount a bar chart, determine if we need to flex the range of the x_scale for date graphing
      let barSpacing = 4;
      let barW = width / longestDataGroupLength;
      let availWidth = (width - padding.right - padding.left)

      let stack_logic = 'max';
      if(chart.chart_type === 'stacked-bar') stack_logic = 'total';

      if(x_axis_type === 'string'){

        x_scale = autoGenerateAxis(data, x_axis_type, chart.x_axis, [padding.left + barSpacing / 2, width - padding.right - barSpacing / 2]);
        barW = x_scale.bandwidth() - barSpacing;

      } else {
        while(barW * (longestDataGroupLength + 1) + barSpacing * (longestDataGroupLength) > availWidth && barSpacing > 0){
          barW--;
          availWidth = width - padding.right - barW - padding.left - barSpacing;
        }
        if(barW < 1) barW = 1;

        x_scale = autoGenerateAxis(data, x_axis_type, chart.x_axis, [padding.left + barW / 2 + barSpacing / 2, width - padding.right - barW / 2 - barSpacing / 2]);
      }

      if(chart.x_axis_sort_direction === "desc") x_scale.domain([x_scale.domain()[1], x_scale.domain()[0]]);

      if(x_axis_type !== 'string'){
        if(chart.x_axis_min !== undefined && chart.x_axis_min !== null && chart.x_axis_min !== '') x_scale.domain([chart.x_axis_min, x_scale.domain()[1]]);
        if(chart.x_axis_max !== undefined && chart.x_axis_max !== null && chart.x_axis_min !== '') x_scale.domain([x_scale.domain()[0], chart.x_axis_max]);
      }
      
      y_scale = autoGenerateAxis(data, y_axis_type, chart.y_axis, [height - padding.bottom, padding.top]);

      if(chart.chart_type === 'stacked-bar'){
        let uniqueXs = [];
        for(var dg in dataGroups){
          for(var i in dataGroups[dg]){
            let index = uniqueXs.findIndex(d => d.x === dataGroups[dg][i].x);
            if(index > -1){
              uniqueXs[index].total_y += parseFloat(dataGroups[dg][i].y)
            } else {
              uniqueXs.push({
                x: dataGroups[dg][i].x,
                total_y: parseFloat(dataGroups[dg][i].y)
              });
            }
          }
        }

        let max = y_scale.domain()[0];
        for(var i in uniqueXs){
          if(uniqueXs[i].total_y > max){
            max = uniqueXs[i].total_y;
          }
        }
        y_scale.domain([y_scale.domain()[0], max]);
      }


      if(y_scale.domain()[0] > 0) y_scale.domain([0, y_scale.domain()[1]]);

      if(chart.y_axis_min !== undefined && chart.y_axis_min !== null  && chart.y_axis_min !== '') y_scale.domain([chart.y_axis_min, y_scale.domain()[1]]);
      if(chart.y_axis_max !== undefined && chart.y_axis_max !== null  && chart.y_axis_max !== '') y_scale.domain([y_scale.domain()[0], chart.y_axis_max]);
      

      ////////////////////////
      //// MOUNT X AXIS 
      ////////////////////////

      if(x_scale){
        let x_axis = d3.axisBottom(x_scale);

        let mounted_x_axis = drawGroup.append("g")
          .attr("transform", "translate(" + 0 + "," + (height - padding.bottom) + ")")
          .call(x_axis)
          ;
        
        if(x_axis_type === 'string' && x_scale.bandwidth){
          mounted_x_axis.selectAll(".tick text")
            .call(wrap, x_scale.bandwidth() - barSpacing - 10);
        }
      }

      ////////////////////////
      //// MOUNT Y AXIS 
      ////////////////////////

      if(y_scale){
        let y_axis = d3.axisLeft(y_scale)
          .ticks(5)
          .tickSize(width - padding.left - padding.right )
          ;

        let y_axis_mounted = drawGroup.append("g")
          .attr("transform", "translate(" + (width - padding.right) + "," + 0 + ")")
          .call(y_axis)
          ;

        setTimeout(()=>{
          let yAxisMaxWidth;
          
          y_axis_mounted.selectAll('text')
            .each(function() { 
              yAxisMaxWidth = this.getBBox().width; 
            })
            ;

          if(this.state.yAxisMaxWidth !== yAxisMaxWidth){
            this.setState({
              yAxisMaxWidth: yAxisMaxWidth
            })
            this.update();
          }
        }, 0)
      }

      ////////////////////////
      //// STYLE TICKS FOR AXIS
      ////////////////////////
      drawGroup.selectAll('path')
        .attr('opacity', 0)
        ;
      drawGroup.selectAll('.tick').selectAll('text')
        .attr('font-size', "10px")
        .attr('opacity', .75)
        ;

      if(x_axis_type === 'date'){
        x_scale
          .range([padding.left + barW / 2, width - padding.right - barW / 2])
      }

      for(var dg in dataGroups){
        let barSVG = drawGroup.selectAll('bars')
          .data(dataGroups[dg])
          .enter()
          ;


        barSVG.append('rect')
            .attr('x', function(d, i){ 
              if(x_axis_type === 'date'){
                return x_scale(new Date(d.x)) - barW / 2;
              }
              if(x_axis_type === 'number'){
                return x_scale(parseFloat(d.x)) - barW / 2;
              }


              return x_scale(d.x + ""); 
            })
            .attr('y', function(d, i){ 
              let retval = y_scale(d.y); 

              for(var j = dg - 1; j >= 0; j--){
                let previousD = dataGroups[j].find(prev => prev.x === d.x);
                if(previousD !== undefined) retval -= (height - padding.bottom) - y_scale(previousD.y);
              }

              if(that.props.hintAtNonZeroBars){
                if(d.y !== 0 && (height - padding.bottom) - retval < 3) retval -= 3;
              } 
              if(retval < 0){
                retval = 0;
              }
              return retval;
            })
            .attr('width', barW)
            .attr('height', function(d, i){ 
              let retval = y_scale(d.y); 
              if(retval < 0){
                retval = 0;
              }

              return height - padding.bottom - retval; 
            })
            .attr('opacity', function(d, i){ 
              if(!d.x) return 0;
              return .8;
            })
            .attr('fill', function(d,i){
              return getColor(dg)
            })
            .attr('index', function(d,i){ return i; })
            .attr('id', function(d,i){ return 'dot_' + chart.i + '_dot_' + dg + '_' + i; })
            .on('mouseover', function(e, d){

              dispatch(showTooltip({
              el: document.getElementById(d3.select(this).attr('id')),
                nobr: false,
                position: 'top',
                content: <div className="text-center">
                  {d.y}
                </div>
              }))
              
            })
            .on('mouseout', function(e,d){
              dispatch(hideTooltip());
            })
            ;
      }

      drawGroup.append('line')
        .attr('x1', padding.left)
        .attr('y1', y_scale(0))
        .attr('x2', width - padding.right)
        .attr('y2', y_scale(0))
        .attr('stroke', '#000')
        .attr('stroke-width', 2)
        ;
    }



    if(chart.chart_type === 'line-chart' || chart.chart_type === 'multi-line-chart'){
      if(!chart.x_axis || !chart.y_axis) return;

      padding = {
        top: 10,
        bottom: 15 + this.state.xAxisWordWraps * 12,
        left: 5 + this.state.yAxisMaxWidth,
        right: 5
      }

      let x_axis_type = chart.x_axis_type || that.state.fields.find(f => f.name === chart.x_axis).type;
      let y_axis_type = 'number';// chart.y_axis_type || that.state.fields.find(f => f.name === chart.y_axis).type;

      let x_scale = autoGenerateAxis(data, x_axis_type, chart.x_axis, [padding.left, width - padding.right - padding.left]);
      let y_scale = autoGenerateAxis(data, y_axis_type, chart.y_axis, [height - padding.bottom, padding.top]);

      if(x_axis_type === 'number'){
        for(var i in dataGroups){
          for(var j = 0; j < dataGroups[i].length; j++){
            dataGroups[i][j].x = parseFloat(dataGroups[i][j].x);
            if(isNaN(dataGroups[i][j].x)){
              dataGroups[i].splice(j, 1);
              j--;
            }
          }
        }
      }


      // if(chart.x_axis_sort){
        let sortBy = chart.x_axis_sort === 'measure' ? 'y' : 'x';
        for(var i in dataGroups){
          dataGroups[i].sort((a,b)=>{
            let av = a[sortBy];
            let bv = b[sortBy];

            if(chart.x_axis_sort_direction === "desc"){
              if(av < bv) return 1;
              if(av > bv) return -1;
            } else {
              if(av < bv) return -1;
              if(av > bv) return 1;
            }
          })
        }
      // }


      if(chart.x_axis_sort_direction === "desc") x_scale.domain([x_scale.domain()[1], x_scale.domain()[0]]);
      if(x_axis_type !== 'string'){
        let currentDomain = x_scale.domain();
        if(chart.x_axis_min !== undefined && chart.x_axis_min !== null && chart.x_axis_min !== '') x_scale.domain([chart.x_axis_min, currentDomain[1]]);
        if(chart.x_axis_max !== undefined && chart.x_axis_max !== null && chart.x_axis_max !== '') x_scale.domain([currentDomain[0], chart.x_axis_max]);
      }

      if(y_scale.domain()[0] > 0) y_scale.domain([0, y_scale.domain()[1]]);

      if(chart.y_axis_min !== undefined && chart.y_axis_min !== null  && chart.y_axis_min !== '') y_scale.domain([chart.y_axis_min, y_scale.domain()[1]]);
      if(chart.y_axis_max !== undefined && chart.y_axis_max !== null  && chart.y_axis_max !== '') y_scale.domain([y_scale.domain()[0], chart.y_axis_max]);

      // before we mount a bar chart, determine if we need to flex the range of the x_scale for date graphing
      let barSpacing = 20;
      let barW = (width - padding.left - padding.right - barSpacing * (data.length)) / data.length;
        
      while(barW < 2 * barSpacing){
        barSpacing--;
        barW = (width - padding.left - padding.right - barSpacing * (data.length)) / data.length;
      }
      if(barW < 1) barW = 1;

      ////////////////////////
      //// MOUNT X AXIS 
      ////////////////////////

      if(x_scale){
        let x_axis = d3.axisBottom(x_scale);

        let mounted_x_axis = drawGroup.append("g")
          .attr("transform", "translate(" + 0 + "," + (height - padding.bottom) + ")")
          .call(x_axis)
          ;
        
        if(x_axis_type === 'string'){
          mounted_x_axis.selectAll(".tick text")
            .call(wrap, x_scale.bandwidth() - barSpacing);
        }
      }

      ////////////////////////
      //// MOUNT Y AXIS 
      ////////////////////////

      if(y_scale){
        let y_axis = d3.axisLeft(y_scale)
          .ticks(5)
          .tickSize(width - padding.left - padding.right )
          ;

        let y_axis_mounted = drawGroup.append("g")
          .attr("transform", "translate(" + (width - padding.right) + "," + 0 + ")")
          .call(y_axis)
          ;

        setTimeout(()=>{
          let yAxisMaxWidth;
          
          y_axis_mounted.selectAll('text')
            .each(function() { 
              yAxisMaxWidth = this.getBBox().width; 
            })
            ;

          if(this.state.yAxisMaxWidth !== yAxisMaxWidth){
            this.setState({
              yAxisMaxWidth: yAxisMaxWidth
            })
            this.update();
          }
        }, 0)
      }

      ////////////////////////
      //// STYLE TICKS FOR AXIS
      ////////////////////////
      drawGroup.selectAll('path')
        .attr('opacity', 0)
        ;
      drawGroup.selectAll('.tick').selectAll('text')
        .attr('font-size', "10px")
        .attr('opacity', .75)
        ;

      if(x_axis_type === 'date') x_scale.range([padding.left + barW / 2, width - padding.right - barW / 2])

      for(var dg in dataGroups){

        let straightLineFunction = d3.line()
          .x((d, i) => { 
            if(x_axis_type === 'date'){
              return x_scale(new Date(d.x));   
            }

            if(x_axis_type === 'string'){
              return x_scale(d.x) + x_scale.bandwidth() / 2;
            }
            return x_scale(d.x); 
          })
          .y((d) => { 
            if(y_axis_type === 'date'){
              return y_scale(new Date(d.y));   
            }
            if(y_axis_type === 'string'){
              return y_scale(d.y) + y_scale.bandwidth() / 2;
            }
            return y_scale(parseFloat(d.y));
          })
          .curve(d3.curveMonotoneX)
          ;

        let lineThisSvg = drawGroup.append('path')
          .attr('d', straightLineFunction(dataGroups[dg]))
          .attr('fill', 'none')
          .attr('stroke', getColor(dg))
          .attr('stroke-width', 2)
          .attr('stroke-linecap', 'ROUND')
          .attr('stroke-linejoin', 'ROUND')
          ;


        let dotSVG = drawGroup.selectAll('bar')
          .data(dataGroups[dg])
          .enter()
          ;

        dotSVG.append('circle')
          .attr('cx', function(d, i){ 
            if(x_axis_type === 'date'){
              return x_scale(new Date(d.x));   
            }
            if(x_axis_type === 'string'){
              return x_scale(d.x) + x_scale.bandwidth() / 2;
            }
            return x_scale(d.x);
          })
          .attr('cy', function(d, i){ 
            if(y_axis_type === 'date'){
              return y_scale(new Date(d.y));   
            }
            if(y_axis_type === 'string'){
              return y_scale(d.y) + y_scale.bandwidth() / 2;
            }
            return y_scale(parseFloat(d.y));
          })
          .attr('r', function(d, i){ 
            // if(that.props.radius){
            //   if(that.props.radiusType === 'date'){
            //     return r_scale(new Date(d.r));
            //   }
            //   return r_scale(d.r); 
            // }
            return 4;
          })
          .attr('opacity', function(d, i){ 
            return .8;
          })
          .attr('fill', getColor(dg))
          // .attr('class', 'mega-chart-fill')
          .attr('index', function(d,i){ return i; })
          .attr('id', function(d,i){ return 'dot_' + chart.i + '_dot_' + dg + '_' + i; })
          .on('mouseenter', function(e, d){
            let index = parseInt(d3.select(this).attr('index'));

            dispatch(showTooltip({
              el: document.getElementById(d3.select(this).attr('id')),
              nobr: false,
              position: 'top',
              content: <div className="text-center">
                {d.y}
              </div>
            }))
            
          })
          .on('mouseout', function(e,d){
            dispatch(hideTooltip());
          })
          ;
      }

      drawGroup.append('line')
        .attr('x1', padding.left)
        .attr('y1', y_scale(0))
        .attr('x2', width - padding.right)
        .attr('y2', y_scale(0))
        .attr('stroke', '#000')
        .attr('stroke-width', 2)
        ;
    }
  }

  render(){
    const { dispatch, dashReducer, guiReducer, chart, view } = this.props;

    if(!chart) return <span/>;


    let mode = chart.mode;
    if(!mode) mode = 'table';

    let data = rebuildData(chart.result);

    let editingThisChart = false;
    if(dashReducer.editingChart){
      if(dashReducer.editingChart.chart_id === chart.i){
        editingThisChart = true;
      }
    }



    return  <div className={"dashboard-chart " + (editingThisChart ? "dashboard-chart-editing" : "")}>
      {
        mode === 'edit' ?
        <div className="flex-split flex-split-align-start">
          <div className="drag-handle flex-grow thin-line-height">
            <span className="text-900">Editing Chart Details</span><br/>
            <small className="thin-line-height">Configure this title, description, layout and visualization of this data.</small>
          </div>
          <div className="list-right">
            <CustomButton
              size="small"
              display="Cancel"
              color="transparent"
              onClick={e => {
                dispatch(updateChart({
                  view_id: view.id,
                  chart_id: chart.i,
                  data:{
                    mode: this.state.previousMode || 'chart'
                  }
                }));
                dispatch(setHistory())
                setTimeout(this.update,250);
              }}
              />
            <CustomButton
              size="small"
              display="Save Changes"
              color="success"
              onClick={e => {
                dispatch(updateChart({
                  view_id: view.id,
                  chart_id: chart.i,
                  data: {
                    title: this.state.title,
                    description: this.state.description,
                    chart_type: this.state.chart_type,
                    x_axis: this.state.x_axis,
                    y_axis: this.state.y_axis
                  }
                }))

                dispatch(updateChart({
                  view_id: view.id,
                  chart_id: chart.i,
                  data:{
                    mode: this.state.previousMode || 'chart'
                  }
                }));
                dispatch(setHistory())

                // this.setState({mode: this.state.previousMode});
                setTimeout(this.update, 250);
              }}
              />
          </div>
        </div>
        :
        <div className="flex-split flex-split-align-start">
          <div className="drag-handle flex-grow  thin-line-height">
            <span className="text-900">{chart.title}</span><br/>
            <small className="thin-line-height">{chart.description}</small>
          </div>
          <div className="list-right">
            {
              dashReducer.tryingToDesignChart[view.id + '_' + chart.i] && <i className="fal fa-spinner-third fa-spin"/>
            }

            <i className="fal fa-comment fa-fw clickable" onClick={e => {
              dispatch(sendChatMessage("Describe something interesting about this chart: (" + chart.i + ") " + chart.title))
            }}/>    

            {
              (chart.chart_type !== undefined && chart.chart_type !== 'no-chart') &&
              <i className={"fa-table clickable fa-fw " + (mode === 'table' ? "far text-secondary" : "fal text-muted")} onClick={e => {
                this.setState({mode: 'table'})
                dispatch(updateChart({
                  view_id: view.id,
                  chart_id: chart.i,
                  data:{
                    mode: 'table'
                  }
                }));
                dispatch(setHistory());
              }}/>          
            }
            
            {
              (chart.chart_type !== undefined && chart.chart_type !== 'no-chart') &&
              <i className={"fa-chart-line clickable fa-fw " + (mode === 'chart' ? "far text-primary" : "fal text-muted")} onClick={e => {
                this.setState({mode: 'chart'});
                dispatch(updateChart({
                  view_id: view.id,
                  chart_id: chart.i,
                  data:{
                    mode: 'chart'
                  }
                }));
                dispatch(setHistory());
                setTimeout(this.update,10);
              }}/>          
            }
            

            <Dropdown
              target={<i className="fal fa-angle-down"/>}
              align={"right"}
              items={[
                <span onClick={e => {
                  // this.setState({
                  //   mode: 'edit',
                  //   title: chart.title,
                  //   description: chart.description,
                  //   chart_type: chart.chart_type,
                  //   x_axis: chart.x_axis,
                  //   y_axis: chart.y_axis,
                  //   previousMode: chart.mode,
                  // })
                  // dispatch(updateChart({
                  //   view_id: view.id,
                  //   chart_id: chart.i,
                  //   data:{
                  //     mode: 'edit'
                  //   }
                  // }));

                  dispatch(setEditingChart({
                    view_id: view.id,
                    chart_id: chart.i
                  }))
                }}>
                  <i className="fal fa-pencil fa-fw icon-before-text"/>Edit Chart
                </span>
                ,
                'divider'
                ,
                <span onClick={e => {
                  dispatch(newView({
                    mode: 'query',
                    sql: chart.sql,
                    query_description: chart.query_description,
                    result: chart.result,
                    data: data
                  }))
                  dispatch(setHistory());
                }}>
                  <i className="fal fa-search-plus text-blue fa-fw icon-before-text"/>Open as New Query
                </span>,
                <span onClick={e => {
                  this.setState({
                    mode: 'delete',
                    previousMode: chart.mode,
                  })

                  dispatch(updateChart({
                    view_id: view.id,
                    chart_id: chart.i,
                    data:{
                      mode: 'delete'
                    }
                  }));
                }}>
                  <i className="fal fa-trash fa-fw icon-before-text text-danger"/>Delete Chart
                </span>
              ]}
              />
          </div>
        </div>
      }

      {
        mode === 'chart' &&
        <div className="dashboard-chart-svg-wrapper" id={"dashboard-chart-svg-wrapper-" + chart.i}>
          <div className="dashboard-chart-svg-child" id={"dashboard-chart-svg-child-" + chart.i}>
            <svg id={"dashboard-chart-svg-" + chart.i}/>
          </div>
        </div>
      }

      {
        (mode === 'chart' && this.state.dataGroups.length > 1) && <div className="dashboard-chart-key-row">
          {
            this.state.dataGroups.map((d,i)=>{
              if(!d[0]) return;
              return <span key={i} className="margin-right-1rem">
                <i className="fas fa-square icon-before-text" style={{color: getColor(i)}}/>
                <small>{d[0].split_key || <i>blank</i>}</small>
              </span>
            })
          }
        </div>
      }

      {
        mode === 'delete' && 
        <div className="flex-grow scroll-parent">
          <div className="scroll-child flex-column-center-center padding-2rem">
            <p className="text-center">Are you sure you want to delete this chart?</p>
            <div className="text-center">
              <CustomButton
                display="Cancel"
                color="transparent"
                onClick={e => {
                  
                  dispatch(updateChart({
                    view_id: view.id,
                    chart_id: chart.i,
                    data:{
                      mode: this.state.previousMode || 'chart'
                    }
                  }));
                  dispatch(setHistory())
                  setTimeout(this.update,250);
                }}
                size="small"
                />
                &nbsp;
              <CustomButton
                display="Delete"
                color="danger"
                onClick={e => {
                  dispatch(deleteChart(chart));
                  dispatch(setHistory());
                }}
                size="small"
                />
            </div>
          </div>
        </div>
      }
      {
        mode === 'table' &&
        <div className="flex-grow scroll-parent">
          <div className="scroll-child">
            <CustomTable
              headers={chart.result.columns.map(h => {
                return {
                  name: h,
                  display: h,
                  getter: (r, i) => <div className="text-ellipsis-parent"><span>{r[h]}</span></div>,
                  sortGetter: (r, i) => r[h]
                }
              })}
              rawData={data || []}
              />
          </div>
        </div>
      }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { guiReducer, dashReducer } = state;

  return {
    guiReducer,
    dashReducer
  }
}

export default connect(mapStateToProps)(DashboardChart);

  