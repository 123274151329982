import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import Modal from '../../kit/components/Modal/Modal'

import downloadFile from '../../utilities/downloadFile';

import {
  toggleFileUploader,
  clearHistory,
  loadChatHistory,
  tryToAddFileToDB,
  loadDashHistory,
  setHistory
} from '../../actions/actions.export'

import './Header.scss';

import version from '../../version';


class Header extends Component {

  constructor(props){
    super(props)

    this.state = {
      resetModal: false
    }

    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.download = this.download.bind(this);
  }


  download(){
    const { dispatch, chatReducer, dashReducer } = this.props;

    let data = {
      messages: chatReducer.messages,
      // dashboards: chatReducer.dashboards,
      usage: chatReducer.usage,
      db: dashReducer.data,
      views: dashReducer.views,
      currentView: dashReducer.currentView
    }

    for(var i in data.views){
      if(data.views[i].mode === 'table'){
        delete data.views[i].data;
        delete data.views[i].result;
      }
    }

    for(var i in data.db){
      delete data.db[i].data;
      delete data.db[i].result;
    }


    const bytes = new TextEncoder().encode(JSON.stringify(data,null,2));
    const blob = new Blob([bytes], {
        type: "application/json;charset=utf-8"
    });
    downloadFile('DashGPT_' + new Date().toISOString() +'.json', blob);
  }

  componentDidMount(){
    const fileInput = document.getElementById('file-input');
    fileInput.addEventListener('change', this.handleFileSelect);
  }

  handleFileSelect(event){
    const { dispatch } = this.props;

    // Retrieve the selected file from the input element
    const file = event.target.files[0];

    // Check that the file is a JSON file
    if (!file.name.endsWith('.json')) {
      alert('Please select a JSON file.');
      return;
    }

    // Create a new file reader
    const reader = new FileReader();

    // Set up an event listener for when the reader finishes reading the file
    reader.onload = (event) => {
      try {
        // Parse the JSON contents of the file
        const data = JSON.parse(event.target.result);
        // Do something with the parsed data here

        if(data){
          dispatch(loadChatHistory({
            messages: data.messages,
            usage: data.usage
          }));
          for(var i in data.db){
            dispatch(tryToAddFileToDB(data.db[i]));
          }
          dispatch(loadDashHistory({
            views: data.views || [],
            currentView: data.currentView
          }))
        } else {
          dispatch(setHistory());
        }
      } catch (error) {
        alert('Error parsing JSON file.');
      }
    };

    // Read the contents of the file as text
    reader.readAsText(file);
  }

  render(){
    const { dispatch, dashReducer } = this.props;

    return <div className={"header flex-split"}>


      <div className="list-left">
        <div className="brand">
          <i className="fal fa-head-side-brain icon-before-text text-blue"/>DashGPT
        </div>
        <small className="text-muted">v{version}</small>

        <Modal
          show={this.state.resetModal}
          exitable={true}
          onExit={e => this.setState({resetModal: false})}
          cancelable={true}
          onCancel={e => this.setState({resetModal: false})}
          acceptable={true}
          acceptButtonColor="danger"
          acceptButtonLabel="Reset Everything"
          onAccept={e => dispatch(clearHistory())}
          content={<div>
            <p>Are you sure you want to reset DashGPT?</p>
            <p>This will clear all loaded data, dashboards, and queries. It cannot be undone.</p>
          </div>}
          />
      </div>
      <input type="file" id="file-input" style={{display: 'none'}} accept=".json"/>
      {
        dashReducer.data.length > 0 ?
        <div className="list-right">
          <CustomButton
            display={<span><i className="fal fa-upload icon-before-text"/>Add .CSV Data</span>}
            size="small"
            color="grey"
            onClick={e => { dispatch(toggleFileUploader())}}
            />
          <CustomButton
            display={<span><i className="fal fa-arrow-down icon-before-text"/>Export</span>}
            size="small"
            color="grey"
            onClick={this.download}
            />
          <CustomButton
            display={<span><i className="fal fa-arrow-up icon-before-text"/>Import</span>}
            size="small"
            color="grey"
            onClick={()=>{
              const fileInput = document.getElementById('file-input');
              fileInput.click();
            }}
            />

          <CustomButton
            display={<span><i className="fal fa-trash icon-before-text"/>Reset DashGPT</span>}
            size="small"
            color="grey"
            onClick={e => { this.setState({resetModal: true})}}
            />
        </div>
        :
        <div className="list-right">
          <CustomButton
            display={<span><i className="fal fa-arrow-up icon-before-text"/>Import</span>}
            size="small"
            color="grey"
            onClick={()=>{
              const fileInput = document.getElementById('file-input');
              fileInput.click();
            }}
            />
        </div>
      }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { dashReducer, chatReducer } = state;

  return {
    dashReducer, chatReducer
  }
}

export default connect(mapStateToProps)(Header);

  