import shortid from 'shortid'

export const dashReducer = (state = {
  data: [],
  currentView: "",
  queryAutoConvert: false,
  showFileUploader: false,
  views: [
    // {
    //   id: "test",
    //   display_name: "Test 1"
    // },
    // {
    //   id: "test2",
    //   display_name: "Test 2"
    // }
  ],
  describeTableForm: {errors: {}, lastSubmit: {}},
  describeSQLForm: {errors: {}, lastSubmit: {}},
  convertToSQLForm: {errors: {}, lastSubmit: {}},
  tryingToDescribeTable: {},
  tryingToDesignChart: {}
}, action) => {

  switch(action.type){

  case 'TOGGLE_FILE_UPLOADER':

      return {
        ...state,
        showFileUploader: action.data === undefined ? !state.showFileUploader : action.data
      }

    case 'NEW_VIEW':
      var views = state.views;

      var newID = shortid.generate();

      var display_name = "Untitled ";

      if(action.data.mode === 'query') display_name += "Query";
      if(action.data.mode === 'dashboard') display_name += "Dashboard";

      views.push({
        display_name: display_name,
        mode: action.data.mode,
        id: newID,
        ...action.data
      })

      return {
        ...state,
        views: views,
        currentView: newID
      }


    case 'DUPLICATE_VIEW':
      var views = state.views;
      var view = JSON.parse(JSON.stringify(action.data));
      view.id = shortid.generate();
      view.display_name = "Copy of " + view.display_name;

      views.push(view);


      return {
        ...state,
        views: views,
        currentView: view.id
      }



    case 'DELETE_VIEW':

      var views = state.views;
      var currentView = state.currentView;
      var viewIndex = views.findIndex(v => v.id === action.data);

      if(viewIndex > -1){
        views.splice(viewIndex, 1);

        if(viewIndex > views.length - 1) viewIndex = views.length - 1;
        currentView = (views[viewIndex] || {}).id;
      }
      
      return {
        ...state,
        views: views,
        currentView: currentView
      }





    case 'UPDATE_VIEW':

      var views = state.views;
      var viewIndex = views.findIndex(v => v.id === action.data.id);

      if(viewIndex > -1){
        views[viewIndex] = {
          ...views[viewIndex],
          ...action.data
        }

      }
      
      return {
        ...state,
        views: views
      }



    case 'UPDATE_CHART':

      var views = state.views;
      var viewIndex = views.findIndex(v => v.id === action.data.view_id);

      if(viewIndex > -1){
        var chartIndex = views[viewIndex].layout.findIndex(c => c.i === action.data.chart_id)
        if(chartIndex > -1){
          views[viewIndex].layout[chartIndex] = {
            ...views[viewIndex].layout[chartIndex],
            ...action.data.data
          }
        }
      }
      
      return {
        ...state,
        views: views
      }





    case 'ADD_TO_DASHBOARD':

      var views = state.views;
      var viewIndex = views.findIndex(v => v.id === action.data.id);

      if(viewIndex > -1){
        if(!views[viewIndex].layout) views[viewIndex].layout = [];
         
        views[viewIndex].layout.push({
          i: shortid.generate(),
          x: 0,
          y: 0,
          w: 6,
          h: 8,
          data: action.data.data,
          result: action.data.result,
          sql: action.data.sql,
          query_description: action.data.query_description
        })

      }
      
      return {
        ...state,
        views: views
      }




    case 'DELETE_CHART':

      var views = state.views;
      var viewIndex = views.findIndex(v => v.id === state.currentView);
      if(viewIndex > -1){
        var chartIndex = views[viewIndex].layout.findIndex(c => c.i === action.data.i);
        if(chartIndex > -1){
          views[viewIndex].layout.splice(chartIndex, 1);
        }
      }
      
      return {
        ...state,
        views: views
      }






    case 'UPDATE_DATA':

      var data = state.data;
      var dataIndex = data.findIndex(v => v.id === action.data.id);

      if(dataIndex > -1){
        data[dataIndex] = {
          ...data[dataIndex],
          ...action.data
        }

      }
      
      return {
        ...state,
        data: data
      }




    case 'SET_EDITING_CHART':

      return {
        ...state,
        editingChart: action.data
      }


    case 'MOVE_VIEW':

      
      var viewIndex = state.views.findIndex(v => v.id === action.data.id);
      var views = moveObjectInArray(state.views, viewIndex, action.data.direction);

      return {
        ...state,
        views: views
      }



    case 'SET_VIEW':
      
      return {
        ...state,
        currentView: action.data
      }

    case 'LOAD_DASH_HISTORY':

      var views = action.data.views;
      var currentView = action.data.currentView;

      if(views.length === 0){
        views.push({
          id: shortid.generate(),
          display_name: 'Untitled View',
          mode: 'query'
        })
        currentView = views[0].id;
      }
      
      if(!views.find(v => v.id === currentView)){
        currentView = views[0].id;
      }

      return {
        ...state,
        views: views,
        currentView: currentView
      }


    case 'ADD_FILE_TO_DB_SUCCESS':

      var views = state.views;
    
      if(action.data.sql_table_name){
        views.push({
          display_name: action.data.sql_table_name,
          mode: 'table',
          table: action.data.sql_table_name,
          id: action.data.sql_table_name
        })
      }

      var data = state.data;
      data.push(action.data);

      return {
        ...state,
        dbLive: true,
        data: data,
        views: views
      }

    case 'ADD_DATA':
      var data = state.data;
      data.push(action.data);

      return {
        ...state,
        data: data
      }
      break;




    case 'TOGGLE_QUERY_AUTOCONVERT':

      return {
        ...state,
        queryAutoConvert: action.data
      }



    case 'REQUEST_CONVERT_TO_SQL':
      
      return {
        ...state,
        tryingToConvertToSQL: true,
        convertToSQLForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CONVERT_TO_SQL_FAIL':

      
      return {
        ...state,
        tryingToConvertToSQL: false,
        convertToSQLForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }


    case 'RECEIVE_CONVERT_TO_SQL_SUCCESS':

      var viewIndex = state.views.findIndex(v => v.id === action.data.view_id);
      var views = state.views;
      if(viewIndex > -1){
        views[viewIndex].sql = action.data.response.output_data.content
      }

      return {
        ...state,
        views: views,
        tryingToConvertToSQL: false,
        convertToSQLForm: {errors: {}, lastSubmit: {}}
      }


    case 'RUN_QUERY_SUCCESS':

      
      var views = state.views;
      if(action.data.view_id){
        var viewIndex = state.views.findIndex(v => v.id === action.data.view_id);
        if(viewIndex > -1){
          views[viewIndex].result = action.data.result;
          views[viewIndex].data = action.data.data;
          views[viewIndex].error = undefined;
        }
      }

      var data = state.data;
      if(action.data.data_id){
        var dataIndex = state.data.findIndex(v => v.id === action.data.data_id);
        if(dataIndex > -1){
          data[dataIndex].result = action.data.result;
          data[dataIndex].data = action.data.data;
          data[dataIndex].error = undefined;
        }
      }

      return {
        ...state,
        views: views,
        data: data
      }


    case 'RUN_QUERY_FAIL':

      
      var views = state.views;
      if(action.data.view_id){
        var viewIndex = state.views.findIndex(v => v.id === action.data.view_id);
        if(viewIndex > -1){
          views[viewIndex].error = action.data.error.message;
          views[viewIndex].result = undefined;
          views[viewIndex].data = undefined;
        }
      }

      var data = state.data;
      if(action.data.data_id){
        var dataIndex = state.data.findIndex(v => v.id === action.data.data_id);
        if(dataIndex > -1){
          data[dataIndex].error = action.data.error.message;
          data[dataIndex].result = undefined;
          data[dataIndex].data = undefined;
        }
      }

      return {
        ...state,
        views: views,
        data: data
      }

    case 'REQUEST_DESCRIBE_SQL':
      
      return {
        ...state,
        tryingToDescribeSQL: {[action.data.lastSubmit.data_id]: true,},
        describeSQLForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DESCRIBE_SQL_FAIL':

      
      return {
        ...state,
        tryingToDescribeSQL: {[action.data.lastSubmit.data_id]: false,},
        describeSQLForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }


    case 'RECEIVE_DESCRIBE_SQL_SUCCESS':

      var viewIndex = state.views.findIndex(v => v.id === action.data.view_id);
      var views = state.views;
      if(viewIndex > -1){
        views[viewIndex].query_description = action.data.response.output_data.content
      }

      return {
        ...state,
        views: views,
        tryingToDescribeSQL: {[action.data.data_id]: false,},
        describeSQLForm: {errors: {}, lastSubmit: {}}
      }



    case 'REQUEST_DESCRIBE_TABLE':
      
      return {
        ...state,
        tryingToDescribeTable: {[action.data.lastSubmit.data_id]: true},
        describeTableForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DESCRIBE_TABLE_FAIL':

      
      return {
        ...state,
        tryingToDescribeTable: {[action.data.lastSubmit.data_id]: false},
        describeTableForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }


    case 'RECEIVE_DESCRIBE_TABLE_SUCCESS':

      var dataIndex = state.data.findIndex(v => v.id === action.data.data_id);
      var data = state.data;
      if(dataIndex > -1){
        data[dataIndex].table_description = action.data.response.output_data.content
        console.log('found', data[dataIndex].table_description);
      }

      return {
        ...state,
        data: data,
        tryingToDescribeTable: {[action.data.data_id]: false},
        describeTableForm: {errors: {}, lastSubmit: {}}
      }



    case 'REQUEST_DESIGN_CHART':
      
      return {
        ...state,
        tryingToDesignChart: {[action.data.lastSubmit.view_id + '_' + action.data.lastSubmit.chart_id]: true,},
        designChartForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DESIGN_CHART_FAIL':

      
      return {
        ...state,
        tryingToDesignChart: {[action.data.lastSubmit.view_id + '_' + action.data.lastSubmit.chart_id]: false,},
        designChartForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }


    case 'RECEIVE_DESIGN_CHART_SUCCESS':

      var viewIndex = state.views.findIndex(v => v.id === action.data.view_id);
      var views = state.views;
      if(viewIndex > -1){
        var chartIndex = views[viewIndex].layout.findIndex(c => c.i === action.data.chart_id);
        if(chartIndex > -1){
          views[viewIndex].layout[chartIndex] = {
            ...views[viewIndex].layout[chartIndex],
            ...action.data.response
          }
        }
      }

      return {
        ...state,
        views: views,
        tryingToDesignChart: {[action.data.view_id + '_' + action.data.chart_id]: false,},
        designChartForm: {errors: {}, lastSubmit: {}}
      }



    default:
      return state
  }
}


///
///
/// 
///  HELPER FUNCTIONS
///
///
///

function moveObjectInArray(array, index, direction) {
  if (index < 0 || index >= array.length) {
    // Invalid index, do nothing
    return array;
  }

  const newIndex = parseInt(index) + parseInt(direction);

  if (newIndex < 0 || newIndex >= array.length) {
    // New index is out of bounds, do nothing
    return array;
  }

  const newArray = [...array];
  const movedObject = newArray[index];
  newArray.splice(index, 1);
  newArray.splice(newIndex, 0, movedObject);

  return newArray;
}