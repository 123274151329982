import React, { useState, useEffect } from 'react';

import shortid from 'shortid';
let id = shortid.generate();

function ChatTypewriter(props) {
  const [text, setText] = useState("");
  

  useEffect(() => {
    if(props.onStart) props.onStart();

    let index = 0;
    let lastMessageHeight = 0;

    const nextChar = () => {
      if (index < props.text.length) {
        setText((prevState) => prevState + props.text.charAt(index));
        index++;
        var messageDiv = document.getElementById(id);
        if(messageDiv){
          let newHeight = messageDiv.getBoundingClientRect().height;
          if(lastMessageHeight !== newHeight){

            lastMessageHeight = newHeight;
            var objDiv = document.getElementById("custom-chat-convo");
            if(objDiv){
              if(objDiv.scrollHeight - objDiv.scrollTop - objDiv.getBoundingClientRect().height < 75){
                objDiv.scrollTop = objDiv.scrollHeight;
              }
            }
          }
        }
        setTimeout(nextChar, Math.random() * props.delay)
      } else {
        var objDiv = document.getElementById("custom-chat-convo");
        if(objDiv){
          if(objDiv.scrollHeight - objDiv.scrollTop - objDiv.getBoundingClientRect().height < 75){
            objDiv.scrollTop = objDiv.scrollHeight;
          }
        }
        if(props.onComplete) props.onComplete();
      }
    }


    let timer = setTimeout(nextChar, Math.random() * props.delay);
    return;// () => clearInterval(timer);
  }, [props.text, props.delay]);

  return (
    <span id={id}>{text}</span>
  );
}

export default ChatTypewriter;