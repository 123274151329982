import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Responsive, WidthProvider } from "react-grid-layout";

import DashboardChart from '../../components/DashboardChart/DashboardChart'

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import CustomField from '../../kit/components/CustomField/CustomField'
import CustomTable from '../../kit/components/CustomTable/CustomTable'
import TabNav from '../../kit/components/TabNav/TabNav'
import Callout from '../../kit/components/Callout/Callout'


import {
  updateView,
  setHistory,
} from '../../actions/actions.export'

import './DashboardView.scss';


const ResponsiveGridLayout = WidthProvider(Responsive);

let resizeTimeout; 


class DashboardView extends Component {

  constructor(props){
    super(props);

    this.state = {
      resizeCounter: 0
    }

    this.handleResize = this.handleResize.bind(this);
  }


  handleResize(){
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(()=>{
      this.setState({resizeCounter: this.state.resizeCounter + 1});  
    }, 100);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }


  render(){
    const { dispatch, dashReducer, guiReducer, view } = this.props;

    let layout = view.layout || [
      ];

    return  <div className="workspace-section-child-body dashboard-view flex-column-stretch" key={view}>
      <div className="flex-split">

      </div>
      <div className="flex-column-stretch flex-grow">
        <ResponsiveGridLayout
          className="layout"
          layouts={{lg: layout}}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          rowHeight={25}
          width={'100%'}
          draggableHandle=".drag-handle"
          onResize={e => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(()=>{
              this.setState({resizeCounter: this.state.resizeCounter + 1});  
            }, 100);
          }}
          onLayoutChange={newLayout => {
            let newView = view;
            for(var i in newView.layout){
              let chartToUpdate = newLayout.find(d => d.i === newView.layout[i].i);
              newView.layout[i].x = chartToUpdate.x;
              newView.layout[i].y = chartToUpdate.y;
              newView.layout[i].w = chartToUpdate.w;
              newView.layout[i].h = chartToUpdate.h;
            }
            dispatch(updateView(newView));
            dispatch(setHistory());
          }}
          >
          {
            layout.map((chart, i)=>{
              return <div key={chart.i}><DashboardChart chart={chart} view={view} resizeCounter={this.state.resizeCounter}/></div>
            })
          }
        </ResponsiveGridLayout>
      </div>                 
    </div>
  }
}


const mapStateToProps = (state) => {
  const { guiReducer, dashReducer } = state;

  return {
    guiReducer,
    dashReducer
  }
}

export default connect(mapStateToProps)(DashboardView);

  