
export const guiReducer = (state = {
  sideTray: 'docked',
  showContentPane: true,
  componentContentView: 'grid'
}, action) => {

  switch(action.type){
    case 'TOGGLE_CONTENT_PANE':
      
      return {
        ...state,
        showContentPane: action.data
      }
      break;


    case 'SET_COMPONENT_CONTENT_VIEW':
      
      return {
        ...state,
        componentContentView: action.data
      }
      break;


    case 'OPEN_SIDE_TRAY':
      
      return {
        ...state,
        sideTray: 'open'
      }
      break;


    case 'CLOSE_SIDE_TRAY':
      
      return {
        ...state,
        sideTray: 'close'
      }
      break;


    case 'DOCK_SIDE_TRAY':
      
      return {
        ...state,
        sideTray: 'docked'
      }
      break;


    case 'ROTATE_SIDE_TRAY':

      let s = state.sideTray;
      if(s === "open"){
        s = "docked";
      } else if(s === "docked"){
        s = "open"; //"closed";
      } else if(s === "closed"){
        s = "open"; // temp disabled
      }

      
      return {
        ...state,
        sideTray: s
      }
      break;


    default:
      return state
  }
}
