import {
  tryToLoadHistory,
  tryToDescribeTable,
  setHistory
} from './actions.export'

export * from './dash/a.dash.POST.convert-to-sql.js';
export * from './dash/a.dash.POST.describe-sql.js';
export * from './dash/a.dash.POST.describe-table.js';
export * from './dash/a.dash.POST.design-chart.js';

const initSqlJs = require('sql.js');

let db;

export const tryToCreateDatabase = packet => async (dispatch, getState) => {
  
  const SQL = await initSqlJs({
    // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
    // You can omit locateFile completely when running in node
    locateFile: file => { return './sql-wasm.wasm'}
  });

  // Create a database
  db = new SQL.Database();
  console.log("db created", db);

  dispatch(tryToLoadHistory());
}


export const tryToAddFileToDB = packet => async (dispatch, getState) => { 

  packet.errors = [];

  try{
    db.exec(packet.create_script); 
  } catch (e){
    console.log(e);
    return;
  }


  for(var i in packet.inserts){

    try{
      db.exec(packet.inserts[i]);
    } catch (e){
      console.log(e);
      packet.errors.push({
        message: "Issue inserting row.",
        rowNumber: i,
        details: e
      })
    }
  }

  dispatch(addFileToDBSuccess(packet));
  dispatch(tryToRunQuery({
    data_id: packet.sql_table_name,
    sql: 'select * from ' + packet.sql_table_name
  }))
  if(!packet.table_description){
    dispatch(tryToDescribeTable({
      data_id: packet.sql_table_name,
      create_script: packet.create_script,
      example_data: JSON.stringify(packet.inserts.slice(0,2))
    }))
  }
}


export const tryToRunQuery = packet => async (dispatch, getState) => {

  try{
    console.log(packet.sql);
    if(packet.sql.indexOf('```sql') > -1 || packet.sql.indexOf('```SQL') > -1){
      packet.sql = packet.sql.split('```')[1];
      packet.sql = packet.sql.substr(3, packet.sql.length);
    }

    console.log(packet.sql);

    let result = await db.exec(packet.sql)[0];

    console.log("QUERY RESULT", result);

    let data = [];
    if(result){
      for(var i = 0; i < result.values.length; i++){
        let d = {};
        for(var j in result.columns){
          d[result.columns[j]] = result.values[i][j];
        }

        data.push(d);
      }
    }
      
    dispatch(runQuerySuccess({
      ...packet,
      result: result,
      data: data
    }));

    dispatch(setHistory());
  } catch(e){

    dispatch(runQueryFail({
      ...packet,
      error: e
    }));

    dispatch(setHistory());
  }
}


export const runQuerySuccess = data => ({
  type: 'RUN_QUERY_SUCCESS',
  data
})

export const runQueryFail = data => ({
  type: 'RUN_QUERY_FAIL',
  data
})



export const addFileToDBSuccess = data => ({
  type: 'ADD_FILE_TO_DB_SUCCESS',
  data
})


export const loadDashHistory = data => ({
  type: 'LOAD_DASH_HISTORY',
  data
})

export const newView = data => ({
  type: 'NEW_VIEW',
  data
})

export const moveView = data => ({
  type: 'MOVE_VIEW',
  data
})

export const updateView = data => ({
  type: 'UPDATE_VIEW',
  data
})

export const deleteView = data => ({
  type: 'DELETE_VIEW',
  data
})

export const duplicateView = data => ({
  type: 'DUPLICATE_VIEW',
  data
})

export const setView = data => ({
  type: 'SET_VIEW',
  data
})

export const updateData = data => ({
  type: 'UPDATE_DATA',
  data
})

export const toggleQueryAutoConvert = data => ({
  type: 'TOGGLE_QUERY_AUTOCONVERT',
  data
})


export const toggleFileUploader = data => ({
  type: 'TOGGLE_FILE_UPLOADER',
  data
})



export const addToDashboard = data => ({
  type: 'ADD_TO_DASHBOARD',
  data
})

export const deleteChart = data => ({
  type: 'DELETE_CHART',
  data
})

export const updateChart = data => ({
  type: 'UPDATE_CHART',
  data
})

export const setEditingChart = data => ({
  type: 'SET_EDITING_CHART',
  data
})

