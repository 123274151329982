import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import CustomButton from '../kit/components/CustomButton/CustomButton.js'

class ErrorRoute extends Component {
  componentDidMount(){
    window.document.title = "zerowidth.ai - oops";
  }

  render(){
    const { guiReducer, userReducer } = this.props;

    let hfStretchedClassList = "hf-stretched";
    
    return <div className={hfStretchedClassList}>     
      <div className="body flex-column-center-center">
        <h1 className="no-margin">oops!</h1>
        <h4>Something's not right if you ended up here...</h4>
        <div className="spacer-2rem"/>
        <CustomButton
          to="/"
          display="Go Home"
          />
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  const { userReducer, guiReducer } = state;

  return {
    userReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(ErrorRoute);

  