import {
  setHistory,
  tryToRunQuery,
  addToast
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Convert text to SQL
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestConvertToSQL = packet => {
  return {
    type: 'REQUEST_CONVERT_TO_SQL',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveConvertToSQLSuccess = data => ({
  type: 'RECEIVE_CONVERT_TO_SQL_SUCCESS',
  data
})

export const receiveConvertToSQLFail = data => ({
  type: 'RECEIVE_CONVERT_TO_SQL_FAIL',
  data
})

export const tryToConvertToSQL = packet => (dispatch, getState) => {

  dispatch(requestConvertToSQL(packet));


  let headers = {
    "Content-Type": "application/json"
  }

  return fetch('/api/dash/convert-to-sql', {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveConvertToSQLSuccess({
        view_id: packet.view_id,
        response: json
      }));

      if(packet.runImmediately){
        dispatch(tryToRunQuery({
          view_id: packet.view_id,
          sql: json.output_data.content
        }))
      }
      dispatch(setHistory());
    })
    .catch(e => {
      
      dispatch(receiveConvertToSQLFail({errors:e, lastSubmit: packet}));
      dispatch(addToast({
        title: "Failed to Respond",
        // detail: e.error || e,
        autoDismiss: 5000
      }))
    })
}
