import { push } from 'react-router-redux';
import Cookies from 'js-cookie';
import shortid from 'shortid';

import addCookieWithConsent from '../../utilities/addCookieWithConsent';

import {
  chatAddBotMessage,
  setHistory,
  addToast
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Send messages to server
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestSendMessages = packet => {
  return {
    type: 'REQUEST_SEND_MESSAGES',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveSendMessagesSuccess = data => ({
  type: 'RECEIVE_SEND_MESSAGES_SUCCESS',
  data
})

export const receiveSendMessagesFail = data => ({
  type: 'RECEIVE_SEND_MESSAGES_FAIL',
  data
})

export const tryToSendMessages = packet => (dispatch, getState) => {

  let id = shortid.generate();


  const state = getState();

  let description = state.dashReducer.data.map(d => d.create_script + "\n" + d.table_description).join('\n\n');
  packet.table_descriptions = description;

  let currentView = state.dashReducer.views.find(v => v.id === state.dashReducer.currentView);
  if(currentView){
    packet.current_tab = currentView.display_name;

    if(currentView.mode === 'table'){
      packet.current_tab += " (a preview of the SQL table)"
    }

    if(currentView.mode === 'dashboard'){
      packet.current_tab += " (a dashboard with a grid layout of various charts and tables)"
    }

    if(currentView.mode === 'query'){
      packet.current_tab += " (a SQL query editor view, with both a pseudo code description, SQL code, and results)"
    }

    packet.tab_details = state.dashReducer.views.map(v => { return {name: v.display_name, type: v.mode }})
    packet.current_tab_details = JSON.stringify(currentView, null, 2);
  }

  let chatReducer = state.chatReducer;

  // packet.dashboard = chatReducer.dashboards.find(d => d.id === chatReducer.currentDashboard);
  // packet.role = chatReducer.role;

  dispatch(requestSendMessages({
    id: id,
    packet: packet
  }));

  console.log(packet);

  let headers = {
    "Content-Type": "application/json"
  }

  return fetch('/api/chat', {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      let currentID = getState().chatReducer.messagesForm.lastSubmit.id;
      if(currentID === id){
        dispatch(receiveSendMessagesSuccess(json));
        dispatch(chatAddBotMessage(json))
        dispatch(setHistory());
      }

    })
    .catch(e => {
      
      dispatch(receiveSendMessagesFail({errors:e, lastSubmit: packet}));
      dispatch(addToast({
        title: "Failed to Respond",
        // detail: e.error || e,
        autoDismiss: 5000
      }))
    })
}
