
const categorizeValues = (values) => {
  if (values.length === 0) return 'unknown';

  const isDate = value => {
    const date = new Date(value);
    return !isNaN(date.getTime()) && typeof value === 'string';
  };

  const isNumber = value => {
    // Check if value is already a number and not NaN
    if (typeof value === 'number' && !isNaN(value)) {
      return true;
    }

    // If value is a string, check if it can be parsed as a float
    if (typeof value === 'string') {
      const parsed = parseFloat(value);
      return !isNaN(parsed) && value.trim() !== '';
    }

    // All other types or conditions
    return false;
  };

  // const isNumber = value => !isNaN(value) && value !== null && value !== '' && value !== true && value !== false;

  let dateCount = 0;
  let numberCount = 0;

  for (const value of values) {
    if(value !== undefined && value !== '' && value !== null){
      if (isDate(value)) {
        dateCount++;
      } 
      if (isNumber(value)) {
        numberCount++;
      }
    }
  }
  
  if (numberCount === values.length) return 'number';
  if (dateCount === values.length) return 'date';

  // Default to string if not all are dates or numbers
  return 'string';
}

export default categorizeValues;