import moment from 'moment'
import shortid from 'shortid'
import Cookies from 'js-cookie';



export const chatReducer = (state = {
  tool: undefined,
  messages: [
    {
      role: 'assistant',
      content: `Welcome to ZeroWidth's DashGPT, how can I help you?`
    }
  ],
  messagesForm: {errors: {}, lastSubmit: {}}
}, action) => {

  switch(action.type){


    case 'CHAT_ADD_USER_MESSAGE':

      var messages = state.messages;

      messages.push({
        content: action.data,
        id: shortid.generate(),
        role: 'user',
        timestamp: new Date().toISOString()
      })

      return {
        ...state,
        messages
      }

    case 'CHAT_ADD_BOT_MESSAGE':
      
      var messages = state.messages;

      if(action.data.output_data){
        messages.push({
          content: action.data.output_data.content,
          id: shortid.generate(),
          role: 'assistant',
          timestamp: new Date().toISOString()
        })
      }
      
      return {
        ...state,
        messages
      }


    case 'REMOVE_CHAT_MESSAGE':
      
      var messages = state.messages;

      messages.splice(action.data, 1);
      
      return {
        ...state,
        messages
      }

    case 'RESET_CONVERSATION':
      
      return {
        ...state,
        messages: []
      }


    case 'REQUEST_SEND_MESSAGES':
      
      return {
        ...state,
        tryingToSendMessages: true,
        messagesForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SEND_MESSAGES_FAIL':

      var messages = state.messages;
      var messageIndex = messages.findIndex(m => m.content.text === state.messagesForm.lastSubmit.packet.messages[state.messagesForm.lastSubmit.packet.messages.length - 1].content);
      if(messageIndex > -1){
        messages[messageIndex].failed = true;
      }
      
      return {
        ...state,
        tryingToSendMessages: false,
        messages: messages,
        messagesForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }


    case 'RECEIVE_SEND_MESSAGES_SUCCESS':

      var messages = state.messages;
      var messageIndex = messages.findIndex(m => m.content.text === state.messagesForm.lastSubmit.packet.messages[state.messagesForm.lastSubmit.packet.messages.length - 1].content);
      if(messageIndex > -1 && messages[messageIndex].failed){
        messages[messageIndex].failed = false;
      }

      var dashboards = state.dashboards;
      if(action.data.dashboard){
        var dashIndex = dashboards.findIndex(d => d.id === action.data.dashboard.id);
        if(dashIndex > -1){
          dashboards[dashIndex] = action.data.dashboard;
        }

        for(var i in dashboards[dashIndex].layout){
          if(!dashboards[dashIndex].layout[i].i){
            dashboards[dashIndex].layout[i].i = shortid.generate();
          }
        }
      }

      // action.data.usage.percent = action.data.usage.total_tokens / 4096;
      
      return {
        ...state,
        tryingToSendMessages: false,
        messages: messages,
        dashIndex: dashIndex,
        usage: action.data.usage || state.usage,
        messagesForm: {errors: {}, lastSubmit: {}}
      }


    case 'LOAD_CHAT_HISTORY':

      if(!action.data.messages){
        action.data.messages = state.messages;
      }
      if(!action.data.dashboards || action.data.dashboards.length === 0){
        action.data.dashboards = state.dashboards;
        // action.data.currentDashboard = state.dashboards[0].id;
      } else if(action.data.dashboards.length > 0){
        // action.data.currentDashboard = action.data.dashboards[0].id;
      }

      return {
        ...state,
        ...action.data
      }


    default:

      return state
  }
}
