const spacing = 12;

const tooltip = (state = {
  show: false,
  content: '',
  lastShowMillis: 0,
  lastHideMillis: 0,
  d: {}
}, action) => {

  switch(action.type){
    case 'SHOW_TOOLTIP_NOW':

      var d = action.data.d;

      // d.y -= window.scrollY;
      d.x += window.scrollX;

      var top, bottom, left, right;
      
      if((d.y - d.height) < 20){
        action.data.position = 'bottom';
      } else if(d.y + d.height > window.innerHeight){
        action.data.position = 'top';
      }
      
      if((d.x + d.width) > window.innerWidth - 50){
        action.data.position = 'left';
        // console.log(d, window.innerWidth);
      } else if((d.x) < 50){
        action.data.position = 'right';
        // console.log(d, window.innerWidth);
      }


      switch(action.data.position){
        case 'bottom':
          left = d.x + d.width / 2;
          top = d.y + d.height + spacing;
          break;
        case 'top':
          left = d.x + d.width / 2;
          bottom =  window.innerHeight - d.y + spacing; //document.body.scrollHeight
          break;
        case 'left':
          left = d.x - spacing;
          top = d.y + d.height / 2;
          break;
        case 'right':
          left = d.x + d.width + spacing;
          top = d.y + d.height / 2;
          break;

        default:
          break;
      }

      return {
        ...state,
        show: true,
        lastShowMillis: new Date().getTime(),
        content: action.data.content,
        position: action.data.position,
        d: d,
        nobr: action.data.nobr,
        top: top,
        bottom: bottom,
        left: left,
        right: right
      }

    case 'HIDE_TOOLTIP_NOW':
      return {
        ...state,
        lastHideMillis: new Date().getTime(),
        show: false,
        // content: ''
      }
        
    default:
      return state
  }
}

export default tooltip;