import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Responsive, WidthProvider } from "react-grid-layout";
import { compress, decompress } from 'compress-json'

import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'

import 'react-reflex/styles.css'

import Header from '../components/Header/Header'
import Filez from '../components/Filez/Filez'
import QueryView from '../components/QueryView/QueryView'
import TableView from '../components/TableView/TableView'
import DashboardView from '../components/DashboardView/DashboardView'
import ChartEditor from '../components/ChartEditor/ChartEditor'

import CustomField from '../kit/components/CustomField/CustomField'
import CustomButton from '../kit/components/CustomButton/CustomButton'
import Chat from '../kit/components/Chat/Chat'
import Dropdown from '../kit/components/Dropdown/Dropdown'
import Modal from '../kit/components/Modal/Modal'

import {
  setHistory,
  addChart,
  saveLayout,
  tryToCreateDatabase,
  newView,
  setView,
  duplicateView,
  deleteView,
  updateView,
  moveView,
  sendChatMessage,
  removeChatMessage,
  resetConversation
} from '../actions/actions.export'


let resizeTimeout; 

class HomeRoute extends Component {
  constructor(props){
    super(props);

    this.state = {
      resizeWorkspaceCounter: 1,
      resizeCounter: 0,
      data: [],
      editingDisplayName: "",
      currentNav: "database",
      navMenu: [
        {
          name: 'database',
          display_name: 'Database Config',
          menuOpen: false,
          icon: 'database text-secondary',
          menu: [],
          menuOpen: true
        },
        // {
        //   name: 'dashboards',
        //   display_name: 'Dashboards',
        //   menuOpen: false,
        //   icon: 'tachometer text-primary',
        //   menu: [],
        //   menuOpen: true
        // }
      ]
    }

    this.handleResize = this.handleResize.bind(this);
  }

  handleResize(){
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(()=>{
      this.setState({resizeCounter: this.state.resizeCounter + 1});  
    }, 100);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  static getDerivedStateFromProps(props, state){

    const { dashReducer } = props;


    let missingTables = dashReducer.data.filter(d => !state.navMenu[0].menu.find(m => m.name === d.id));

    for(const d of missingTables){
      state.navMenu[0].menu.push({
        name: d.id,
        display_name: d.sql_table_name,
        icon: 'table text-secondary',
        onClickEventType: "table_mode"
      })
    }



    // let missingDashboards = dashReducer.views.filter(d => !state.navMenu[1].menu.find(m => m.name === d.id) && d.mode === 'dashboard');

    // for(const d of missingDashboards){
    //   state.navMenu[1].menu.push({
    //     name: d.id,
    //     display_name: d.display_name,
    //     icon: 'tachometer text-primary',
    //     onClickEventType: "dashboard_mode"
    //   })
    // }


    // // check for new names
    // // for(var i in state.navMenu[0].menu){
    // //   state.navMenu[0].menu[i].display_name = dashReducer.data.filter(d => d.id === state.navMenu[0].menu[i].name).display_name;
    // // }

    // for(var i = 0; i < state.navMenu[1].menu.length; i++){
    //   let dash = dashReducer.views.find(d => d.id === state.navMenu[1].menu[i].name);
    //   if(dash){
    //     state.navMenu[1].menu[i].display_name = dash.display_name;
    //   } else {
    //     state.navMenu[1].menu.splice(i, 1);
    //     i--;
    //   }
    // }




    return state;
  }



  render(){
    const { dispatch, guiReducer, chatReducer, dashReducer } = this.props;

    let currentDashboard = {
      layout: []
    };


    let editingView = dashReducer.views.find(v => v.id === this.state.renameViewModal || v.id === this.state.deleteViewModal) || {};

    const currentView = dashReducer.views.find(v => v.id === dashReducer.currentView);

    const navMenu = [];

    // for(var i in dashReducer.data){
    //   navMenu[0].menu.push({
    //     name: dashReducer.data[i].id,
    //     display_name: dashReducer.data[i].sql_table_name,
    //     icon: 'table'
    //   })
    // }


    return <div className={"hf-stretched hf-stretched-noscroll"}>
      <Header/>
      <Modal
        show={this.state.deleteViewModal}
        acceptable={true}
        acceptButtonColor="danger"
        acceptButtonLabel="Delete"
        onAccept={e => {
          dispatch(deleteView(this.state.deleteViewModal));
          dispatch(setHistory());
          this.setState({deleteViewModal: false});
        }}
        cancelable={true}
        onCancel={e => this.setState({deleteViewModal: false})}
        exitable={true}
        onExit={e => this.setState({deleteViewModal: false})}
        content={<div>
          <p>
            Are you sure you want to delete this?
          </p>
        </div>}
        />

      <Modal
        show={this.state.renameViewModal}
        acceptable={true}
        acceptButtonColor="success"
        acceptButtonLabel="Save"
        onAccept={e => {
          dispatch(updateView({
            id: editingView.id,
            display_name: this.state.editingDisplayName
          }));
          dispatch(setHistory());
          this.setState({renameViewModal: false});
        }}
        cancelable={true}
        onCancel={e => this.setState({renameViewModal: false})}
        exitable={true}
        onExit={e => this.setState({renameViewModal: false})}
        content={<div>
          <CustomField
            label="Enter a new name for this:"
            value={this.state.editingDisplayName}
            onChange={e => {
              this.setState({
                editingDisplayName: e.value
              })
            }}
            />
        </div>}
        />
        {
          (dashReducer.data.length == 0 || dashReducer.showFileUploader) ?
          <div className="body flex-column-center-center">
            <Filez/>
          </div>
          :
          <div className="workspace-wrapper">
            <div className="workspace">
              <ReflexContainer orientation="vertical">
                <ReflexElement 
                  className="left-pane" 
                  minSize={150} 
                  size={400} 
                  onStopResize={(e)=>{
                    clearTimeout(resizeTimeout);
                    resizeTimeout = setTimeout(()=>{
                      this.setState({resizeWorkspaceCounter: this.state.resizeWorkspaceCounter + 1});  
                    }, 100);
                  }}>
                  {
                    dashReducer.editingChart ? 
                    <div className="workspace-section-parent">
                      <div className="workspace-section-child scroll-parent no-select" key={dashReducer.editingChart.view_id + '_' + dashReducer.editingChart.chart_id}>
                        <div className="scroll-child padding-1rem ">
                          <ChartEditor/>
                        </div>
                      </div>
                    </div>
                  :
                    <ReflexContainer orientation="horizontal">
                      <ReflexElement
                        minSize={100}
                        size={150} 
                        >
                        <div className="workspace-section-parent">
                          <div className="workspace-section-child padding-1rem no-select">
                            {
                              this.state.navMenu.map((topMenu, i)=>{
                                return <div key={i} className="margin-bottom-05rem">
                                  
                                  <div 
                                    className={"clickable text-ellipsis text-hover-primary " + (this.state.currentNav.startsWith(topMenu.name) ? "" : "")}
                                    onClick={e => {
                                      if(topMenu.menu){
                                        // toggle open/close state
                                        let menu = this.state.navMenu;
                                        menu[i].menuOpen = !menu[i].menuOpen;
                                        this.setState({
                                          navMenu: menu
                                        })
                                      } else {
                                        // set current to this item
                                        this.setState({
                                          currentNav: topMenu.name
                                        })
                                      }
                                    }}
                                    >
                                    <span className="margin-right-1rem text-muted">
                                      {
                                        topMenu.menu ? (topMenu.menuOpen ? <i className="fal fa-minus-square fa-fw"/> : <i className="fal fa-plus-square fa-fw"/>)
                                        :
                                        <i className="far fa-blank fa-fw"/>
                                      }
                                    </span>
                                    <i className={"fas fa-" + topMenu.icon + " icon-before-text fa-fw"}/>
                                    {topMenu.display_name}
                                  </div>
                                  {
                                    topMenu.menuOpen &&
                                    (topMenu.menu || []).map((child, j) => {
                                      return <div 
                                        key={j} 
                                        className={"clickable text-ellipsis-parent text-hover-primary " + (this.state.currentNav.startsWith(child.name) ? "" : "")}
                                        style={{marginLeft: 29}}
                                        onClick={e => {
                                          this.setState({
                                            currentNav: child.name
                                          })

                                          if(child.onClickEventType === "table_mode"){
                                            console.log('table_mode');

                                            // look for a view that is this, if not create it
                                            let view = dashReducer.views.find(v => v.mode === 'table' && v.table === child.display_name);

                                            if(view){
                                              dispatch(setView(view.id));
                                            } else {
                                              dispatch(newView({
                                                display_name: child.display_name,
                                                mode: 'table',
                                                table: child.display_name
                                              }))
                                            }
                                          }


                                          if(child.onClickEventType === "dashboard_mode"){
                                            console.log('dashboard_mode');

                                            // look for a view that is this, if not create it
                                            let view = dashReducer.views.find(v => v.mode === 'dashboard' && v.id === child.name);

                                            if(view){
                                              dispatch(setView(view.id));
                                            } else {
                                              dispatch(newView({
                                                display_name: child.display_name,
                                                mode: 'dashboard',
                                                id: child.name
                                              }))
                                            }
                                          }
                                        }}
                                        >
                                        <span className="text-ellipsis"> 
                                          <small><i className={"far fa-" + child.icon + " icon-before-text fa-fw "}/></small>
                                          {child.display_name}
                                        </span>
                                      </div>
                                    })
                                  }
                                </div>
                              })
                            }
                          </div>
                        </div>
                      </ReflexElement>
                      <ReflexSplitter/>
                      <ReflexElement
                        minSize={100}
                        >
                        <div className="workspace-section-parent">
                          <div className="workspace-section-child overflow-hidden">
                            <Chat
                              placeholder="Ask a question about the data"
                              messages={chatReducer.messages}
                              thinking={chatReducer.tryingToSendMessages}
                              onMessageSend={e => {
                                dispatch(sendChatMessage(e));
                                dispatch(setHistory());
                              }}
                              onDeleteMessage={(message, index) => {
                                dispatch(removeChatMessage(index));
                                dispatch(setHistory());
                              }}
                              onDeleteAllMessages={(message, index) => {
                                dispatch(resetConversation());
                                dispatch(setHistory());
                              }}
                              renderRules={[
                                {
                                  rule: (message) => {
                                    if(message.content.indexOf("Describe something interesting about this chart:") === 0){
                                      let parenIndex = message.content.indexOf(')') + 1;
                                      let chart_title = message.content.substring(parenIndex, message.content.length).trim();
                                      return <span>Changing topic to: {chart_title}...</span>
                                    }
                                  }
                                }
                              ]}
                              />
                          </div>
                        </div>
                      </ReflexElement>
                    </ReflexContainer>
                  }
                </ReflexElement>

                <ReflexSplitter/>

                <ReflexElement className="right-pane" minSize={300}>
                  <div className="workspace-section-parent" id="grid-wrapper">
                  
                    <div className="workspace-section-child flex-column-stretch workspace-section-child-left-border " key={chatReducer.currentDashboard + '_' + currentDashboard.layout.length + '_' + this.state.resizeWorkspaceCounter}>
                      <div className="workspace-section-tab-menu">
                        {
                          dashReducer.views.map((view, i)=>{
                            return <div key={i} className={"workspace-section-tab flex-split " + (dashReducer.currentView === view.id ? "workspace-section-tab-selected" : "")} onClick={e => {
                                dispatch(setView(view.id));
                                // dispatch(setHistory());
                              }}>
                              {
                                view.mode === 'query' && <i className="far fa-search icon-before-text text-blue"/>
                              }
                              {
                                view.mode === 'table' && <i className="far fa-table icon-before-text text-secondary"/>
                              }
                              {
                                view.mode === 'dashboard' && <i className="far fa-tachometer icon-before-text text-primary"/>
                              }
                              <div>
                                {view.display_name}
                              </div>
                              <Dropdown
                                target={<i className="fal fa-angle-down icon-after-text margin-left-1rem clickable dropdown-target-icon"/>}
                                items={[
                                  {
                                    content: <span className={view.mode === 'table' ? "text-muted" : ""} onClick={e => 
                                      this.setState({
                                        renameViewModal: view.id,
                                        editingDisplayName: view.display_name
                                      })}
                                    >
                                      <i className="fal fa-pencil icon-before-text"/> Rename
                                    </span>,
                                    disabled: view.mode === 'table'
                                  }
                                  ,
                                  <span onClick={e => {
                                    setTimeout(()=>{
                                      dispatch(moveView({
                                        id: view.id,
                                        direction: -1
                                      }))
                                      dispatch(setHistory());
                                    }, 100);
                                  }}>
                                    <i className="fal fa-arrow-left icon-before-text"/> Move Left
                                  </span>
                                  ,
                                  <span onClick={e => {
                                    setTimeout(()=>{
                                      dispatch(moveView({
                                        id: view.id,
                                        direction: 1
                                      }))
                                      dispatch(setHistory());
                                    }, 100);
                                  }}>
                                    <i className="fal fa-arrow-right icon-before-text"/> Move Right
                                  </span>
                                  ,
                                  <span onClick={e => {
                                    setTimeout(()=>{
                                      dispatch(duplicateView(view))
                                      dispatch(setHistory());
                                    }, 100);
                                  }}>
                                    <i className="fal fa-copy icon-before-text"/> Duplicate
                                  </span>
                                  ,
                                  "divider"
                                  ,
                                  
                                    false ? <span onClick={e => {
                                      let compressed = compress(view);
                                      console.log(view, window.btoa(JSON.stringify(view)), compressed, window.btoa(JSON.stringify(compressed)));

                                    }}>
                                      <i className="fal fa-share icon-before-text"/> Share
                                    </span> : undefined
                                  ,
                                  view.mode === 'table' ? 
                                  <span onClick={e => {
                                    dispatch(deleteView(view.id));
                                    dispatch(setHistory());
                                  }}>
                                    <i className="fal fa-times icon-before-text"/> Close Tab
                                  </span>
                                  :
                                  view.mode === 'query' ?
                                  <span onClick={e => this.setState({deleteViewModal: view.id})}>
                                    <i className="fal fa-trash icon-before-text"/> Delete Query
                                  </span>
                                  :
                                  view.mode === 'dashboard' ?
                                  <span onClick={e => this.setState({deleteViewModal: view.id})}>
                                    <i className="fal fa-trash icon-before-text"/> Delete Dashboard
                                  </span>
                                  : undefined
                                ]}
                                />
                            </div>
                          })
                        }
                        <Dropdown
                          target={
                            <div className="workspace-section-tab">
                              <i className="fal fa-plus text-hover-primary clickable"/>
                            </div>
                          }
                          items={[
                            <span onClick={() => {
                              dispatch(newView({mode: 'query'}));
                              dispatch(setHistory());
                            }}><i className="fal fa-search text-blue icon-before-text"/>New Query</span>,
                            <span onClick={() => {
                              dispatch(newView({mode: 'dashboard'}));
                              dispatch(setHistory());
                            }}><i className="fal fa-tachometer text-primary icon-before-text"/>New Dashboard</span>
                          ]}
                          />
                        
                      </div>
                      {
                        currentView && currentView.mode === 'query' && 
                        <QueryView view={currentView}/>
                      }
                      {
                        currentView && currentView.mode === 'table' && 
                        <TableView view={currentView}/>
                      }
                      {
                        currentView && currentView.mode === 'dashboard' && 
                        <DashboardView view={currentView}/>
                      }
{/*                      <div className="workspace-section-child-body">
                        <div className="flex-split padding-1rem">
                          <div className="list-left">
                            <CustomButton
                              display={<span><i className="far fa-plus icon-before-text"/>Add Chart</span>}
                              color="black"
                              size="small"
                              onClick={e => dispatch(addChart())}
                              />
                          </div>
                          <div className="list-right">
                            <CustomButton
                              display={<span><i className="far fa-file-alt icon-before-text"/>Generate Report</span>}
                              color="grey"
                              size="small"
                              disabled={true}
                              />
                            <CustomButton
                              display={<span><i className="far fa-cog icon-before-text"/>Edit View</span>}
                              color="grey"
                              size="small"
                              onClick={e => this.setState({
                                editViewModal: true, 
                                edit_view_display_name: currentDashboard.display_name,
                                edit_view_description: currentDashboard.description
                              })}
                              />
                          </div>
                        </div>
                        
                      </div>*/}
                    </div>
                  </div>

                </ReflexElement>

              </ReflexContainer>
            </div>
          </div>
        }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { guiReducer, chatReducer, dashReducer } = state;

  return {
    guiReducer,
    dashReducer,
    chatReducer
  }
}

export default connect(mapStateToProps)(HomeRoute);

  