import React, { Component } from 'react'
import shortid from 'shortid'
import { connect } from 'react-redux'
import Select from 'react-select';

import { showTooltip, hideTooltip } from '../../../actions/actions.export.js'

import './CustomSelect.css';

class CustomSelect extends Component {
  constructor(props){
    super(props);

    this.state = {
      id: shortid.generate(),
      blurCounter: 0,
      focusCounter: 0
    }
    
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(e){
    if(e && e.preventDefault) e.preventDefault();
    if(e && e.stopPropagation) e.stopPropagation();

    let value;
    if(e === null){
      value = undefined
    } else {
      value = e.value;
    }

    if(this.props.multiSelect){
      value = e.map(d => d.value);
    }

    if(this.props.onChange){
      this.props.onChange(value);
    }
  }

  handleFocus(e){
    this.setState({
      focusCounter: this.state.focusCounter + 1,
      hasFocus: true
    })

    if(this.props.onFocus) this.props.onFocus();

    setTimeout(()=>{
      let error = this.getError();
      this.renderTooltip(error);  
    })
  }

  handleBlur(e){
    const { dispatch } = this.props;

    if(this.props.onBlur) this.props.onBlur();

    this.setState({
      blurCounter: this.state.blurCounter + 1,
      hasFocus: false
    });

    dispatch(hideTooltip());
  }

  getError(){
    if(this.state.blurCounter > 0){
      if(this.props.required && (this.props.value === undefined || this.props.value === null)){
        return "Required.";
      }
    }

    if(this.props.serverError && this.props.lastSubmit === this.props.value){
      return this.props.serverError;
    }

    return false;
  }

  componentDidUpdate(){
    const { dispatch } = this.props;

    let error = this.getError();
    if(this.state.error !== error){
      this.renderTooltip(error);

      this.setState({
        error: error
      })
    }
  }

  renderTooltip(error){
    const { dispatch } = this.props;

    if(error && this.state.hasFocus){
      dispatch(showTooltip({
        el: document.getElementById('field_' + this.props.name + '_' + this.state.id),
        nobr: false,
        position: 'right',
        content: <div style={{maxWidth: 150}} className="text-danger text-center">
          {error}
        </div>
      }))
    } else {
      dispatch(hideTooltip());
    }
  }

  render(){    

    let classList = "";
    if(this.props.mini) classList += " custom-select-mini";
    if(this.props.large) classList += " custom-select-large";
    if(this.props.inline) classList += " custom-select-inline";

    let options = this.props.options.map((o) => {
      if(typeof o !== "object"){
        return {
          label: o,
          value: o
        }
      }
      return o;
    });


    let value;

    if(this.props.multiSelect){
      value = [];
      for(var i in this.props.value){
        value.push(options.find(o => o.value === this.props.value[i]));
      }
    } else {
      value = options.find(o => o.value === this.props.value);
    }

    return <div className={"custom-select " + classList} disabled={this.props.disabled || this.props.thinking} style={{minWidth: this.props.minWidth}}>
      {
        this.props.label &&
        <div className="custom-select-label">
          {this.props.label}{this.props.required && <span className="custom-select-required">*</span>}&nbsp;
        </div>
      }
      <Select
        id={'field_' + this.props.name + '_' + this.state.id}
        className={"react-select-container " + (this.state.error ? "input-error" : "")}
        classNamePrefix="react-select"
        value={value}
        isDisabled={this.props.disabled}
        isMulti={this.props.multiSelect}
        onChange={this.handleChange}
        onInputChange={this.props.onInputChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        placeholder={this.props.placeholder}
        noOptionsMessage={this.props.noOptionsMessage}
        isClearable={this.props.isClearable}
        options={options}
        menuPortalTarget={this.props.target ? document.getElementById(this.props.target) : undefined}
      />
      {
        !this.props.inline && <div className={"custom-select-description " + (this.state.error ? "custom-select-description-error" : "")}>
          {this.props.description}
        </div>
      }
    </div>
  }
};

const mapStateToProps = (state) => {
  // const {  } = state;

  return {
    // styleReducer
  }
}

export default connect(mapStateToProps)(CustomSelect);
