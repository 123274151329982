import React from 'react'

import { tryToSendMessages } from './chat/a.chat.POST.js'

export * from './chat/a.chat.POST.js';
// export * from './chat/a.chat.POST.view.js';
// export * from './chat/a.chat.POST.query.js';
// export * from './chat/a.chat.POST.write-query.js';
// export * from './chat/a.chat.POST.fetch-data.js';
// export * from './chat/a.chat.POST.generate-chart.js';



export const chatAddUserMessage = data => ({
  type: 'CHAT_ADD_USER_MESSAGE',
  data
})

export const chatAddBotMessage = data => ({
  type: 'CHAT_ADD_BOT_MESSAGE',
  data
})

export const removeChatMessage = data => ({
  type: 'REMOVE_CHAT_MESSAGE',
  data
})

export const resetConversation = data => ({
  type: 'RESET_CONVERSATION',
  data
})

export const loadChatHistory = data => ({
  type: 'LOAD_CHAT_HISTORY',
  data
})


export const sendChatMessage = packet => (dispatch, getState) => {

  dispatch(chatAddUserMessage(packet));

  let messages = getState().chatReducer.messages.map(m => {
    return {
      role: m.role,
      content: m.content
    }
  });

  dispatch(tryToSendMessages({
    messages: messages
  }))
}